import { RapidTest, Test241, TestEACH } from "./types";
import notificationBell from "../assets/pleasing-bell.mp3";

export const stepDateTime = (
  dateTime: string,
  component: string,
  steps: number,
  timezone: boolean = false
) => {
  let result = new Date(dateTime); // convert input dateTime to Date
  if (timezone) {
    let tzOffset = new Date(dateTime).getTimezoneOffset() * 60000; // get timezone offset in milliseconds
    result = new Date(result.getTime() - tzOffset); // take timezone offset off of Date
  }
  switch (component) {
    case "mins":
      result.setMinutes(result.getMinutes() + 60 * steps);
      break;
    case "hours":
      result.setHours(result.getHours() + 24 * steps);
      break;
    case "days":
      result.setDate(result.getDate() + 7 * steps);
      break;
    case "months":
      result.setMonth(result.getMonth() + 24 * steps);
      break;
    default:
      break;
  }
  let newDate = result.toISOString().substring(0, 19).replace("T", " ");
  return newDate;
};

export function msToTime(duration: number) {
  // milliseconds: string | number = Math.floor((duration % 1000) / 100),
  // hours: string | number = Math.floor((duration / (1000 * 60 * 60)) % 24);
  var seconds: string | number = Math.floor((duration / 1000) % 60),
    minutes: string | number = Math.floor((duration / (1000 * 60)) % 60);

  // hours = (hours < 10) ? "0" + hours.toString() : hours;
  minutes = minutes < 10 ? "0" + minutes.toString() : minutes;
  seconds = seconds < 10 ? "0" + seconds.toString() : seconds;

  return minutes + ":" + seconds;
}

export function calcAvgEACH(
  each0: number,
  each1: number,
  each2: number,
  each3: number
) {
  let eachSum = 0;
  let eachCount = 0;
  if (each0 > 0) {
    eachSum += each0;
    eachCount += 1;
  }
  if (each1 > 0) {
    eachSum += each1;
    eachCount += 1;
  }
  if (each2 > 0) {
    eachSum += each2;
    eachCount += 1;
  }
  if (each3 > 0) {
    eachSum += each3;
    eachCount += 1;
  }
  if (eachCount > 0) {
    return eachSum / eachCount;
  } else {
    return undefined;
  }
}

export function calcMaxEACH(
  each0: number,
  each1: number,
  each2: number,
  each3: number
) {
  let maxEACH = 0;
  if (each0 > maxEACH) {
    maxEACH = each0;
  }
  if (each1 > maxEACH) {
    maxEACH = each1;
  }
  if (each2 > maxEACH) {
    maxEACH = each2;
  }
  if (each3 > maxEACH) {
    maxEACH = each3;
  }
  return maxEACH;
}

export function median(values: Array<number>) {
  values.sort(function (a, b) {
    return a - b;
  });

  var half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];

  return (values[half - 1] + values[half]) / 2.0;
}

export function calcEACH(eachTest: any) {
  let eachSum = 0;
  let eachCount = 0;
  if (eachTest.each0 > 0) {
    eachSum += eachTest.each0;
    eachCount += 1;
  }
  if (eachTest.each1 > 0) {
    eachSum += eachTest.each1;
    eachCount += 1;
  }
  if (eachTest.each2 > 0) {
    eachSum += eachTest.each2;
    eachCount += 1;
  }
  if (eachTest.each3 > 0) {
    eachSum += eachTest.each3;
    eachCount += 1;
  }
  return (eachSum / eachCount).toFixed(2);
}

export function calcResultAverage(testValues: RapidTest | undefined) {
  if (!testValues) return 0;
  let resTotal = 0;
  let resCount = 0;
  if (testValues.result0 > 0) {
    resTotal += testValues.result0;
    resCount++;
  }
  if (testValues.result1 > 0) {
    resTotal += testValues.result1;
    resCount++;
  }
  if (testValues.result2 > 0) {
    resTotal += testValues.result2;
    resCount++;
  }
  if (testValues.result3 > 0) {
    resTotal += testValues.result3;
    resCount++;
  }
  return resTotal / resCount;
}

export function calcResultAvg(testValues: Test241 | TestEACH | undefined) {
  if (!testValues) return 0;
  let resTotal = 0;
  let resCount = 0;
  if (testValues.each0 > 0) {
    resTotal += testValues.each0;
    resCount++;
  }
  if (testValues.each1 > 0) {
    resTotal += testValues.each1;
    resCount++;
  }
  if (testValues.each2 > 0) {
    resTotal += testValues.each2;
    resCount++;
  }
  if (testValues.each3 > 0) {
    resTotal += testValues.each3;
    resCount++;
  }
  return resTotal / resCount;
}

export const isDiffusionComplete = (testValues: Test241 | undefined) => {
  if (!testValues) return false;
  const deviceOnlineCount =
    (testValues.did0 && testValues.did0 > 0 ? 1 : 0) +
    (testValues.did1 && testValues.did1 > 0 ? 1 : 0) +
    (testValues.did2 && testValues.did2 > 0 ? 1 : 0) +
    (testValues.did3 && testValues.did3 > 0 ? 1 : 0);
  let x3count = 0;
  const timeSinceStart =
    new Date().getTime() - new Date(testValues.background_end).getTime();

  if (timeSinceStart > 19 * 60 * 1000) {
    if (
      testValues.device0?.x3_time_e1 ||
      testValues.device0?.x3_time_e2 ||
      testValues.device0?.x3_time_e3 ||
      testValues.device1?.x3_time_e1 ||
      testValues.device1?.x3_time_e2 ||
      testValues.device1?.x3_time_e3 ||
      testValues.device2?.x3_time_e1 ||
      testValues.device2?.x3_time_e2 ||
      testValues.device2?.x3_time_e3 ||
      testValues.device3?.x3_time_e1 ||
      testValues.device3?.x3_time_e2 ||
      testValues.device3?.x3_time_e3
    )
      return true;
  }

  if (
    testValues.device0?.x3_time_e1 &&
    testValues.device0?.x3_time_e2 &&
    testValues.device0?.x3_time_e3
  ) {
    x3count += 1;
  }
  if (
    testValues.device1?.x3_time_e1 &&
    testValues.device1?.x3_time_e2 &&
    testValues.device1?.x3_time_e3
  ) {
    x3count += 1;
  }
  if (
    testValues.device2?.x3_time_e1 &&
    testValues.device2?.x3_time_e2 &&
    testValues.device2?.x3_time_e3
  ) {
    x3count += 1;
  }
  if (
    testValues.device3?.x3_time_e1 &&
    testValues.device3?.x3_time_e2 &&
    testValues.device3?.x3_time_e3
  ) {
    x3count += 1;
  }
  return x3count === deviceOnlineCount && timeSinceStart > 5 * 60 * 1000;
};

export function playNotificationSound() {
  const audio = new Audio(notificationBell);
  audio.play();
}
