import { useEffect, useMemo, useState } from "react";

import { FaCheckCircle } from "react-icons/fa";
import { IoTriangle } from "react-icons/io5";
import { IoTriangleOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";

import DeviceLineChart from "../../components/DeviceLineChart";

import { calcResultAvg } from "../../utils/util";
import { getAqiLevel } from "../../calculations";
import { TestEACH, TestType } from "../../utils/types";

import styles from "./latestMeasurement.module.scss";
import { findTarget } from "../../utils/findTarget";

function LatestMeasurement({
  rapidTest,
  chartData,
  peakOccupancy,
  locationCategory,
  zoneVolume,
  hideEach,
  testType,
}: {
  rapidTest: TestEACH;
  chartData: any;
  peakOccupancy: number;
  locationCategory: string;
  zoneVolume: number;
  hideEach: boolean;
  testType: TestType;
}) {
  const { t } = useTranslation();

  const resultActual = useMemo(() => {
    return parseFloat(
      ((calcResultAvg(rapidTest) * zoneVolume) / (60 * peakOccupancy)).toFixed(
        1
      )
    );
  }, [rapidTest, peakOccupancy, zoneVolume]);

  const resultTarget = useMemo(() => {
    return findTarget(locationCategory) ?? 0;
  }, [locationCategory]);

  const testResult = useMemo(() => {
    if (rapidTest.status !== "success") return undefined;
    if (!resultActual || !resultTarget) return undefined;
    if (resultActual >= resultTarget) return "success";
    else if (resultActual < resultTarget) return "fail";
    else return undefined;
  }, [resultActual, resultTarget, rapidTest]);

  const eachAvg = useMemo(() => calcResultAvg(rapidTest), [rapidTest]);

  const passesForOccupants = useMemo(() => {
    // if test fails, calculate how many occupants can be in the space
    // with the current CFM
    let resultByVolume = calcResultAvg(rapidTest) * zoneVolume;
    if (testResult === "fail")
      for (let occupants = peakOccupancy - 1; occupants > 0; occupants--)
        if (resultByVolume / (60 * occupants) >= resultTarget) return occupants;
    return 0;
  }, [rapidTest, peakOccupancy]);

  const cfmDiff = useMemo(() => {
    return Math.floor(
      Math.abs(resultActual * peakOccupancy - resultTarget * peakOccupancy)
    );
  }, [resultActual, resultTarget]);

  if (rapidTest.status !== "")
    return (
      <div className={styles.root}>
        <h2 className={styles.header}>{t("header.latestMeasurement")}</h2>
        {!hideEach && (
          <div className={styles.eachContainer}>
            <span className={styles.label}>{t("label.each")}</span>
            <p className={styles.value}>
              {eachAvg >= 0 ? eachAvg?.toFixed(1) : "- "}
            </p>
            <p className={styles.subLabel}>
              {`${t("eACH")}`}
              {/* <sub>{"avg"}</sub> */}
              {` (hr`}
              <sup>-1</sup>
              {")"}
            </p>
          </div>
        )}
        <div className={styles.measurementsContainer}>
          <span className={styles.mainValueLabel}>
            {t("label.average-equivalent", {
              people: peakOccupancy ?? 0,
            })}
          </span>
          <span className={styles.measurement}>
            <p className={styles.value}>
              <b>
                {!!resultActual &&
                resultActual >= 0 &&
                rapidTest.status === "success"
                  ? `${resultActual}  `
                  : "- "}
              </b>
            </p>
            <p className={styles.label}>
              <b>{t("label.actual")}</b>
            </p>
            <p className={styles.label}>{t("label.cfm-per-person")}</p>
          </span>
          <span className={styles.measurement}>
            <p className={styles.value}>
              <b>
                {!!resultTarget && resultTarget >= 0
                  ? `${resultTarget} `
                  : "- "}
              </b>
            </p>
            <p className={styles.label}>
              <b>{t("label.target")}</b>
            </p>
            <p className={styles.label}>{t("label.cfm-per-person")}</p>
          </span>
        </div>
        {!!testResult && !!resultActual && !!resultTarget ? (
          <>
            <div
              className={`${styles.testResult} ${
                testResult === "success" ? styles.testPassed : styles.testFailed
              }`}
            >
              <div className={styles.testResultBackground}>
                {testResult === "success" ? (
                  <FaCheckCircle className={styles.checkCircle} />
                ) : (
                  <span className={styles.failIconContainer}>
                    <IoTriangleOutline className={styles.triangleOutline} />
                    <IoTriangle className={styles.triangle} />
                  </span>
                )}
                <span className={styles.textContainer}>
                  <p className={styles.result}>
                    <b>{testResult === "success" ? "PASS" : "FAIL"}</b>
                  </p>
                  <p className={styles.value}>
                    <b>at {peakOccupancy ?? "-"} occupants</b>
                  </p>
                </span>
                {testType === "demo" && (
                  <p className={styles.demoSampleText}>Sample</p>
                )}
              </div>
            </div>
            <div className={styles.differentialContainer}>
              <span className={styles.header}>
                {t("label.average-volumetric-differential")}
              </span>
              <span>
                <p className={styles.value}>
                  <b>{cfmDiff >= 0 ? cfmDiff : "0"}</b>
                </p>
                <p className={styles.label}>
                  <b>
                    {testResult === "fail"
                      ? t("label.additional-cfm-required", {
                          occupants: peakOccupancy,
                        })
                      : t("label.surplus-cfm")}
                  </b>
                </p>
                <p className={styles.label}>
                  <b>{t("label.surplus-cfm-diff")}</b>
                </p>
              </span>
              {testResult === "fail" && !!passesForOccupants && (
                <span className={styles.passesForOccupants}>
                  <b>
                    {t("label.pass-for-occupants", {
                      occupants: passesForOccupants,
                    })}
                  </b>
                </span>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        {!testResult && (
          <DeviceLineChart
            type="PM1.0"
            key="PM1.0 Chart"
            chartData={chartData}
            range={1000}
            colorFunction={(number: number) => {
              return "#64E8DE";
            }}
            levelFunction={getAqiLevel}
            hideTooltip
          />
        )}
      </div>
    );
  else return <></>;
}

export default LatestMeasurement;
