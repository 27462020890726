import React from "react";

import styles from "./baseModal.module.scss";

export type ModalAction = {
  onClick: () => void;
  type: "submit" | "danger" | "green" | "default";
  text: string;
};

interface BaseModalInterface {
  title: string;
  children: React.ReactChild;
  actions?: Array<ModalAction> | ModalAction;
  isOpen: boolean;
}

export default function BaseModal({
  title,
  children,
  actions,
  isOpen,
}: BaseModalInterface) {
  if (isOpen)
    return (
      <div className={styles.root}>
        <div className={styles.modal}>
          <div className={styles.textContainer}>
            <h3 className={styles.typographyTitle3}>{title}</h3>
            <div className={styles.body}>{children}</div>
          </div>
          {actions && "length" in actions && actions?.length ? (
            actions?.map((action) => (
              <button
                className={
                  action.type === "submit"
                    ? styles.submit
                    : action.type === "danger"
                    ? styles.danger
                    : action.type === "green"
                    ? styles.green
                    : styles.default
                }
                onClick={action.onClick}
              >
                {action.text}
              </button>
            ))
          ) : actions && !("length" in actions) ? (
            <button
              className={
                actions.type === "submit"
                  ? styles.submit
                  : actions.type === "danger"
                  ? styles.danger
                  : actions.type === "green"
                  ? styles.green
                  : styles.default
              }
              onClick={actions.onClick}
            >
              {actions.text}
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  else return <></>;
}
