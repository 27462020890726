import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Sensor, TestType } from "../../utils/types";

import styles from "./sensorStatusList.module.scss";

interface SensorStatusListInterface {
  kitDevices?: Array<Sensor>;
  kitPlusDevices?: Array<Sensor>;
  allSensors?: Array<Sensor>;
  kitIds?: Array<string>;
  testType?: TestType;
}

const StatusBubble = ({ online }: { online: boolean }) => (
  <span className={online ? styles.onlineBubble : styles.offlineBubble}></span>
);

export default function SensorStatusList({
  kitDevices,
  kitPlusDevices,
  allSensors,
  // kitIds,
  testType,
}: SensorStatusListInterface) {
  const { t } = useTranslation();

  const activeSensorCount =
    testType === "241" ? 4 : testType === "each" ? 3 : 1;

  const activeSensors = useMemo(() => {
    let onlineKitDevices = kitDevices?.filter((sensor) => sensor.online);
    let onlineAdditionalDevices = kitPlusDevices?.filter(
      (sensor) => sensor.online
    );
    if (
      onlineKitDevices &&
      onlineKitDevices.length < 4 &&
      onlineAdditionalDevices &&
      onlineAdditionalDevices.length
    ) {
      let x =
        activeSensorCount - onlineKitDevices.length >
        onlineAdditionalDevices.length
          ? activeSensorCount - onlineKitDevices.length
          : onlineAdditionalDevices.length > activeSensorCount
          ? activeSensorCount
          : onlineAdditionalDevices.length;
      return [
        ...onlineKitDevices,
        ...onlineAdditionalDevices?.slice(0, x),
      ].slice(0, activeSensorCount);
    } else return onlineKitDevices?.slice(0, activeSensorCount);
  }, [allSensors, kitDevices, kitPlusDevices]);

  const additionalSensors = useMemo(() => {
    let offlineKitDevices = kitDevices?.filter((d) => !d.online);
    let additionalOnlineKitDevices =
      kitDevices?.filter((d) => d.online).slice(activeSensorCount) ?? [];
    let additionalDevices = kitPlusDevices?.filter(
      (d) => !activeSensors?.includes(d)
    );
    if (offlineKitDevices && additionalDevices)
      return [
        ...additionalOnlineKitDevices,
        ...offlineKitDevices,
        ...additionalDevices,
      ];
  }, [allSensors, activeSensors, kitDevices, kitPlusDevices]);

  const additionalOnlineDeviceCount = useMemo(
    () => additionalSensors?.filter((d) => d.online).length,
    [additionalSensors?.length]
  );

  return allSensors?.length ? (
    <>
      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.header}>
            <h3 className={styles.title}>{t("connected-sensors")}</h3>
            {activeSensors && activeSensors.length > 3 ? (
              <StatusBubble online={activeSensors.every((s) => s.online)} />
            ) : (
              <></>
            )}
            {activeSensors?.length}/{activeSensorCount}
          </div>
          <ol className={styles.statusList}>
            {activeSensors?.map((sensor) => (
              <li className={styles.listItem} key={sensor.did}>
                <StatusBubble online={!!sensor.online} />
                {sensor.iotid}
              </li>
            ))}
          </ol>
        </div>
      </div>
      {!!additionalSensors?.length && (
        <div className={styles.root}>
          <div className={styles.container}>
            <div className={styles.header}>
              <h3 className={styles.title}>{t("additional-sensors")}</h3>
              {additionalSensors.length > 3 ? (
                <StatusBubble
                  online={additionalSensors.every((s) => s.online)}
                />
              ) : (
                <></>
              )}
              {additionalOnlineDeviceCount}/{additionalSensors.length}
            </div>
            <ol className={styles.statusList}>
              {additionalSensors.map((sensor) => (
                <li className={styles.listItem} key={sensor.did}>
                  <StatusBubble online={!!sensor.online} />
                  {sensor.iotid}
                </li>
              ))}
            </ol>
          </div>
        </div>
      )}
    </>
  ) : (
    <></>
  );
}
