// pm 1.0, 2.5, 10, & (vocaqi?) charts
// const pmColorStops = [
//   { stopValue: 51, color: '#00b900' },
//   { stopValue: 101, color: '#ffdf00' },
//   { stopValue: 151, color: '#ff7e00' },
//   { stopValue: 201, color: '#ff0000' },
//   { stopValue: 301, color: '#9f124b' },
//   { stopValue: 500, color: '#7e0023' },
// ]
const pm25ColorStops = [
  { stopValue: 0, color: "#00b900" },
  { stopValue: 12.1, color: "#ccb200" },
  { stopValue: 35.5, color: "#ff7e00" },
  { stopValue: 55.5, color: "#ff0000" },
  { stopValue: 150.5, color: "#9f124b" },
  { stopValue: 250.5, color: "#7e0023" },
];
const pm100ColorStops = [
  { stopValue: 0, color: "#00b900" },
  { stopValue: 55, color: "#ccb200" },
  { stopValue: 155, color: "#ff7e00" },
  { stopValue: 255, color: "#ff0000" },
  { stopValue: 355, color: "#9f124b" },
  { stopValue: 455, color: "#7e0023" },
];
// co2 chart
const vocColorStops = [
  { stopValue: 0, color: "#00b900" },
  { stopValue: 101, color: "#ccb200" },
  { stopValue: 151, color: "#ff7e00" },
  { stopValue: 201, color: "#ff0000" },
  { stopValue: 251, color: "#9f124b" },
  { stopValue: 351, color: "#7e0023" },
];
// vocppm chart
const vocppmColorStops = [
  { stopValue: 0, color: "#00b900" },
  { stopValue: 0.8, color: "#00b900" },
  { stopValue: 1.2, color: "#ccb200" },
  { stopValue: 2.8, color: "#ccb200" },
  { stopValue: 3.2, color: "#ff0000" },
];
// const cTempColorStops = [
//   { stopValue: 17, color: '#0000ff' },
//   { stopValue: 30, color: '#00b900' },
//   { stopValue: 65, color: '#ff0000' },

// ]
// const fTempColorStops = [
//   { stopValue: 60, color: '#0000ff' },
//   { stopValue: 85, color: '#00b400' },
//   { stopValue: 150, color: '#ff0000' },
// ]

const rhColorStops = [
  { stopValue: 75, color: "#0000ff" },
  { stopValue: 100, color: "#0000ff" },
];

const atmColorStops = [
  { stopValue: 1500, color: "#00e4e4" },
  { stopValue: 2000, color: "#00e4e4" },
];

const co2ColorStops = [
  { stopValue: 0, color: "#00b900" },
  { stopValue: 500, color: "#00b900" },
  { stopValue: 800, color: "#ccb200" },
  { stopValue: 1200, color: "#ccb200" },
  { stopValue: 1500, color: "#ff0000" },
];

const rapidTestColorStops = [
  { stopValue: 0, color: "#64E8DE" },
  { stopValue: 12.1, color: "#6bc7e0" },
  { stopValue: 35.5, color: "#78a4e5" },
  { stopValue: 55.5, color: "#8A64EB" },
  // { stopValue: 150.5, color: '#9f124b' },
  // { stopValue: 250.5, color: '#7e0023' },
];

function lineChartGradient(chart, chartType, bg = false) {
  // const chart = context.chart
  const { ctx } = chart;

  let gradient = ctx.createLinearGradient(0, 0, 0, 136);
  const yScale = chart.scales.y;
  const yPos = yScale.getPixelForValue(0);

  // if (chartType === 'PM10' || chartType === 'PM2.5' || chartType === 'PM1.0') {

  //   gradient.addColorStop(0.9, "rgba(228, 228, 228, 0)")

  //   return gradient;
  // } else {

  let colorStops = [];
  // let range = 0;
  let offset = 0;
  if (chartType === "C") {
    gradient.addColorStop(1, "rgba(228, 228, 228, 0)");
    gradient.addColorStop(0, "rgba(0, 0, 228, 1)");
    // colorStops = cTempColorStops
    // range += 110
  } else if (chartType === "F") {
    gradient.addColorStop(1, "rgba(228, 228, 228, 0)");
    gradient.addColorStop(0, "rgba(0, 0, 228, 1)");
    // colorStops = fTempColorStops
    // range += 200
  } else if (chartType === "rh") {
    colorStops = rhColorStops;
    // range += 100
    // base 0-5% is white for style purposes
    gradient.addColorStop(1, "rgba(228, 228, 228, 0)");
  } else if (chartType === "atm") {
    colorStops = atmColorStops;
    // range += 2000
    // base 0-100hPa is white for style purposes
    gradient.addColorStop(1, "rgba(228, 228, 228, 0)");
  } else if (chartType === "co2") {
    colorStops = co2ColorStops;
    offset = 500;
    // range += 5000
    // gradient.addColorStop(0.95, "rgba(228, 228, 228, 0)")
  } else if (chartType === "vocppm") {
    colorStops = vocppmColorStops;
    offset = 0.5;
    // range += 20
    // gradient.addColorStop(0.97, "rgba(228, 228, 228, 0)")
  } else if (chartType === "vocaqi") {
    colorStops = vocColorStops;
    offset = 30;
    // range += 500
    // gradient.addColorStop(0.95, "rgba(228, 228, 228, 0)")
  } else if (chartType === "PM10") {
    offset = 15;
    colorStops = pm100ColorStops;
  } else if (chartType === "PM2.5") {
    offset = 15;
    colorStops = pm25ColorStops;
  } else if (chartType === "PM1.0") {
    offset = 15;
    colorStops = rapidTestColorStops;
  }
  // gradient.addColorStop(1, "rgba(228, 228, 228, 0)")
  colorStops.forEach((colorStop) => {
    // let stop = (range - colorStop.stopValue) / range
    let stop = yScale.getPixelForValue(colorStop.stopValue + offset) / yPos;
    let stopColor = colorStop.color;
    if (bg) {
      stopColor += "60";
    } else {
      stopColor += "ff";
    }
    // if (chartType === 'vocaqi') {
    //   console.log(`stop ${stop} stop2 ${stop2}`)
    //   console.log("colorStop: ", colorStop)
    //   console.log("range: ", range);
    //   console.log("stop here: " + stop + "   &   of type: " + chartType);
    // }
    gradient.addColorStop(stop > 1 ? 1 : stop, stopColor);
  });

  return gradient;
  // }
  // gradient.addColorStop(0, "rgba(228, 0, 0, 0.5)")
  // gradient.addColorStop(0.5, "rgba(0, 228, 0, 0.5)")

  // vocppm
  // { stopValue: 2, color: '#00bf00' },
  // { stopValue: 5, color: '#bfbf00' },
  // { stopValue: 20, color: '#bf6800' },
}

export default lineChartGradient;
