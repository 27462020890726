import createDataContext from "./createDataContext";
import senseiApi from "../api/sensei";
import { Action, EACHLocation, Location, Site, Zone } from "../utils/types";

export interface LocationState {
  locations: Array<Location> | null;
  eachLocations: Array<EACHLocation> | null;
  sites: Array<Site> | null;
  zones: Array<Zone> | null;
  location: Location | null;
  site: Site | null;
  zone: Zone | null;
  authError: boolean;

  // eACH legacy code
  currentLocation: number;
  currentRapidTest: number;
  // conditionsInfo: {
  //   email: "";
  //   interventions: "";
  //   cadr: "";
  //   hvac: -1;
  //   notes: "";
  // };
  // eachTests: [];
  // locationNames: [];
  locReady: boolean;
  eachReady: boolean;
  testStatus: string;
  isFirstTest: boolean;
}

interface LocationContext {
  state: LocationState;
  getLocations: () => Promise<void>;
  getSites: (lid: number) => Promise<void>;
  getZones: (lid: number) => Promise<void>;
  getCurrentLocation: (id: number) => Promise<void>;
  getCurrentSite: (id: number) => Promise<void>;
  getCurrentZone: (id: number) => Promise<void>;
  clearError: () => void;
  resetState: () => void;

  getEACHLocations: () => void;

  setTestStatus: (status: string) => void;
  setCurrentLocation: (lid: number) => void;
  setConditionsInfo: (conditions: any) => void;
  setCurrentRapidTest: (tid: number) => void;

  setIsFirstTest: (first: boolean) => void;

  fetchTests: () => void;
}

const locationReducer = (state: LocationState, action: Action) => {
  // console.log(`dispatch(${action.type}): `, action.payload);
  switch (action.type) {
    case "set-locations":
      return { ...state, locations: action.payload, sites: null, zones: null };
    case "set-each-locations":
      return { ...state, eachLocations: action.payload };
    case "set-sites":
      return { ...state, sites: action.payload, zones: null };
    case "set-zones":
      return { ...state, zones: action.payload };
    case "set-location":
      return {
        ...state,
        location: !!action.payload
          ? {
              lid: action.payload.lid,
              name: action.payload.name,
              address: action.payload.address,
              placeid: action.payload.placeid,
              owner: action.payload.owner,
              org: action.payload.org,
              created: action.payload.created,
            }
          : null,
      };
    case "set-site":
      return {
        ...state,
        site: !!action.payload
          ? {
              sid: action.payload.sid,
              name: action.payload.name,
              floorplan: action.payload.floorplan,
              location: action.payload.location,
              owner: action.payload.owner,
              org: action.payload.org,
              created: action.payload.created,
            }
          : null,
      };
    case "set-zone":
      return {
        ...state,
        zone: !!action.payload
          ? {
              zid: action.payload.zid,
              name: action.payload.name,
              site: action.payload.site,
              type: action.payload.type,
              volume: action.payload.volume,
              width: action.payload.width,
              length: action.payload.length,
              height: action.payload.height,
              peak: action.payload.peak,
              configuration: action.payload.configuration,
              owner: action.payload.owner,
              org: action.payload.org,
              created: action.payload.created,
            }
          : null,
      };

    case "set-test-status":
      return { ...state, testStatus: action.payload };
    case "set-conditions-info":
      return { ...state, conditionsInfo: action.payload };
    case "set-current-location":
      return { ...state, currentLocation: action.payload };
    case "set-current-rapidtest":
      return { ...state, currentRapidTest: parseInt(action.payload) };
    case "set-each-tests":
      return { ...state, eachTests: action.payload };
    case "set-location-names":
      return { ...state, locationNames: action.payload, locReady: true };
    case "set-is-first-test":
      return { ...state, isFirstTest: action.payload };
    case "auth-error":
      return { ...state, authError: true };
    case "clear-error":
      return { ...state, authError: false };
    case "reset-state":
      return {
        location: null,
        site: null,
        zone: null,
        sites: null,
        zones: null,
        eachLocations: [],
        currentLocation: -1,
        currentRapidTest: -1,
        locReady: null,
        eachReady: null,
        testStatus: null,
        conditionsInfo: {
          email: "",
          interventions: "",
          cadr: "",
          hvac: -1,
          notes: "",
        },
        isFirstTest: null,
      };
    default:
      return state;
  }
};

const getLocations = (dispatch: Function) => async () => {
  try {
    const response = await senseiApi.get("/locations?level=org", {
      withCredentials: true,
    });
    dispatch({ type: "set-locations", payload: response.data.data });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const getCurrentLocation = (dispatch: Function) => async (lid: number) => {
  // console.log("getCurrentLocation", lid);
  if (!lid) {
    dispatch({ type: "set-location", payload: null });
    return;
  }
  try {
    const response = await senseiApi.get(`/locations/${lid}`, {
      withCredentials: true,
    });
    dispatch({ type: "set-location", payload: response.data.data[0] });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const getSites = (dispatch: Function) => async (lid: number) => {
  if (lid === -1) {
    dispatch({ type: "set-sites", payload: null });
    return;
  }
  try {
    const response = await senseiApi.get(`/sites?level=org&location=${lid}`, {
      withCredentials: true,
    });
    dispatch({ type: "set-sites", payload: response.data.data });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const getCurrentSite = (dispatch: Function) => async (sid: number) => {
  if (sid === -1) {
    dispatch({ type: "set-site", payload: null });
    return;
  }
  try {
    const response = await senseiApi.get(`/sites/${sid}`, {
      withCredentials: true,
    });
    dispatch({ type: "set-site", payload: response.data.data[0] });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const getZones = (dispatch: Function) => async (sid: number) => {
  if (sid === -1) {
    dispatch({ type: "set-zones", payload: null });
    return;
  }
  try {
    const response = await senseiApi.get(`/zones?site=${sid}`, {
      withCredentials: true,
    });
    dispatch({
      type: "set-zones",
      payload: response.data.data,
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const getCurrentZone = (dispatch: Function) => async (zid: number) => {
  if (zid === -1) {
    dispatch({ type: "set-zone", payload: null });
    return;
  }
  try {
    const response = await senseiApi.get(`/zones/${zid}`, {
      withCredentials: true,
    });
    dispatch({ type: "set-zone", payload: response.data.data[0] });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const getEACHLocations = (dispatch: Function) => async () => {
  try {
    const response = await senseiApi.get("/get-locations", {
      withCredentials: true,
    });
    dispatch({ type: "set-each-locations", payload: response.data.data });
    let tempLocationNames: string[] = [];
    response.data.data?.forEach((loc: EACHLocation) => {
      tempLocationNames[loc.ldid] = loc.name;
    });
    dispatch({ type: "set-location-names", payload: tempLocationNames });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const fetchTests = (dispatch: Function) => async () => {
  try {
    const response = await senseiApi.get(`get-each-tests`, {
      withCredentials: true,
    });
    // console.log("get-each-tests, response: ", response.data.data);
    // if (response?.data?.data[0]?.status === "running") {
    //   dispatch({
    //     type: "set-current-rapidtest",
    //     payload: parseInt(response?.data?.data[0]?.tid),
    //   });
    // } //else {
    //   dispatch({ type: 'set-current-rapidtest', payload: -1 })
    // }
    dispatch({ type: "set-each-tests", payload: response.data.data });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const setTestStatus = (dispatch: Function) => (status: string) => {
  // console.log("setting test status: " + status);
  try {
    dispatch({ type: "set-test-status", payload: status });
  } catch (error) {}
};

const setCurrentLocation = (dispatch: Function) => (id: number) => {
  // console.log("setCurrentLocation" + id);
  try {
    dispatch({ type: "set-current-location", payload: id });
  } catch (error) {}
};

const setCurrentRapidTest = (dispatch: Function) => (id: number) => {
  // console.log("setCurrentRapidTest" + id);
  try {
    dispatch({ type: "set-current-rapidtest", payload: id });
  } catch (error) {}
};

const setIsFirstTest = (dispatch: Function) => (payload: boolean) => {
  try {
    dispatch({ type: "set-is-first-test", payload: payload });
  } catch (error) {}
};

const clearError = (dispatch: Function) => () => {
  dispatch({ type: "clear-error" });
};

const resetState = (dispatch: Function) => () => {
  dispatch({ type: "set-location", payload: null });
  dispatch({ type: "set-sites", payload: null });
  dispatch({ type: "set-site", payload: null });
  dispatch({ type: "set-zones", payload: null });
  dispatch({ type: "set-zone", payload: null });
  dispatch({ type: "reset-state", payload: null });
};

export const {
  Provider,
  Context,
}: {
  Provider: ({ children }: { children: any }) => JSX.Element;
  Context: React.Context<LocationContext>;
} = createDataContext(
  locationReducer,
  {
    getLocations,
    getSites,
    getZones,
    getCurrentLocation,
    getCurrentSite,
    getCurrentZone,
    clearError,
    resetState,
    getEACHLocations,
    setTestStatus,
    setCurrentLocation,
    // setConditionsInfo,
    setCurrentRapidTest,
    setIsFirstTest,
    fetchTests,
  },
  {
    locations: null,
    eachLocations: null,
    sites: null,
    zones: null,

    location: null,
    site: null,
    zone: null,
    // why is this here?
    authError: false,

    currentLocation: -1,
    currentRapidTest: -1,
    conditionsInfo: {
      email: "",
      interventions: "",
      cadr: "",
      hvac: -1,
      notes: "",
    },
    isFirstTest: null,
    eachTests: [],
    locationNames: [],
    locReady: false,
    eachReady: false,
    testStatus: "",
  }
);
