import { createRef, useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

import { useTranslation } from "react-i18next";

import { Context as SettingsContext } from "../../context/SettingsContext";

import styles from "./deviceLineChart.module.scss";
import { pcToPm, pm100Aqi, pm25Aqi } from "../../calculations";

import lineChartGradient from "../../utils/lineChartGradient.js";

function DeviceLineChart(props: any) {
  const { t } = useTranslation();

  const externalTooltipHandler = (context: any) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    // const tooltipEl = getOrCreateTooltip(chart);
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = "#ffffff";
      tooltipEl.style.width = "140px";
      tooltipEl.style.borderRadius = "5px";
      tooltipEl.style.color = "black";
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.boxShadow = "rgba(2, 8, 20, 0.1) 0px 0px 10px";

      const data = document.createElement("div");
      data.style.margin = "0px";
      tooltipEl.appendChild(data);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
    // Set Text
    if (tooltip.body) {
      const bodyLines = tooltip.body.map((b: any) => b.lines);

      // const dataHead = document.createElement('div');
      const dataBody = document.createElement("div");
      dataBody.style.display = "flex";
      dataBody.style.flexDirection = "column";
      dataBody.style.justifyContent = "space-between";
      dataBody.style.padding = "2px";

      bodyLines.forEach((body: any, i: number) => {
        const td = document.createElement("div");
        // td.style.borderWidth = 0;

        // div containing header
        const tooltipHeader = document.createElement("div");
        tooltipHeader.style.display = "flex";
        tooltipHeader.style.flexDirection = "row";
        tooltipHeader.style.justifyContent = "space-between";
        tooltipHeader.style.fontSize = "12px";
        tooltipHeader.style.paddingBottom = "5px";

        // div containing time
        const tooltipTime = document.createElement("div");
        tooltipTime.style.display = "flex";
        tooltipTime.style.flexDirection = "row";
        tooltipTime.style.justifyContent = "space-between";
        tooltipTime.style.fontSize = "12px";
        tooltipTime.style.paddingBottom = "10px";

        let timeIndex = 0;

        if (tooltip.title.length > 2) {
          // span for header type label
          const headerLabel = document.createElement("span");
          headerLabel.style.fontWeight = "400";
          headerLabel.style.color = "gray";
          headerLabel.appendChild(document.createTextNode(tooltip.title[0]));

          // span for haeder value & category
          const headerValue = document.createElement("span");
          headerValue.style.fontWeight = "700";
          headerValue.style.color = "black";
          headerValue.style.textAlign = "right";

          // p for value
          const value = document.createElement("p");
          value.style.fontSize = "12px";
          value.innerHTML = tooltip.title[1];
          headerValue.appendChild(value);

          // p for category
          const category = document.createElement("p");
          category.style.fontSize = "11px";
          category.style.whiteSpace = "nowrap";
          category.innerHTML = `(${t(tooltip.title[2])})`;
          headerValue.appendChild(category);

          tooltipHeader.appendChild(headerLabel);
          tooltipHeader.appendChild(headerValue);

          timeIndex = 3;
        }

        // span for time label
        const timeLabel = document.createElement("span");
        timeLabel.style.fontWeight = "400";
        timeLabel.style.fontSize = "12px";
        timeLabel.style.color = "gray";
        timeLabel.appendChild(document.createTextNode(t("time")));

        // span for date & time
        const timeValue = document.createElement("span");
        timeValue.style.fontWeight = "400";
        timeValue.style.color = "gray";
        timeValue.style.textAlign = "right";

        let fullTime = tooltip.title[timeIndex].split(",");

        // p for date
        const value = document.createElement("p");
        value.style.fontSize = "12px";
        value.innerHTML = `${fullTime[0]},`;
        timeValue.appendChild(value);

        // p for time
        const time = document.createElement("p");
        time.style.fontSize = "12px";
        time.innerHTML = fullTime[1];
        timeValue.appendChild(time);

        tooltipTime.appendChild(timeLabel);
        tooltipTime.appendChild(timeValue);

        // div containing footer
        const tooltipFooter = document.createElement("div");
        tooltipFooter.style.display = "flex";
        tooltipFooter.style.flexDirection = "row";
        tooltipFooter.style.alignItems = "center";
        tooltipFooter.style.fontSize = "12px";
        tooltipFooter.style.paddingBottom = "5px";

        // span for AQI color circle
        const colorBox = document.createElement("span");
        colorBox.style.backgroundColor =
          tooltip.dataPoints[0].dataset.pointHoverBackgroundColor[
            tooltip.dataPoints[0].parsed.x
          ];
        colorBox.style.marginRight = "10px";
        colorBox.style.borderRadius = "50%";
        colorBox.style.height = "10px";
        colorBox.style.width = "10px";
        colorBox.style.display = "inline-block";

        // p for value
        const footerValue = document.createElement("p");
        footerValue.style.fontSize = "14px";
        footerValue.style.fontWeight = "700";
        footerValue.style.color = "black";
        // footerValue.style.marginLeft = '2px'
        footerValue.innerHTML = tooltip.title[timeIndex + 1];

        tooltipFooter.appendChild(colorBox);
        tooltipFooter.appendChild(footerValue);

        td.appendChild(tooltipHeader);
        td.appendChild(tooltipTime);
        td.appendChild(tooltipFooter);

        dataBody.appendChild(td);
      });

      const dataRoot = tooltipEl.querySelector("div");

      // Remove old children
      while (dataRoot.firstChild) {
        dataRoot.firstChild.remove();
      }

      // Add new children
      // dataRoot.appendChild(dataHead);
      dataRoot.appendChild(dataBody);
    }

    tooltipEl.style.transform = `translate(${
      tooltip.caretX > 335 / 2 ? "-108%" : "8%"
    }, -50%)`;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left =
      (tooltip.caretX < tooltip.width / 2
        ? tooltip.width / 2
        : tooltip.caretX + tooltip.width / 2 > 335
        ? 335 - tooltip.width / 2
        : tooltip.caretX) + "px";
    tooltipEl.style.top =
      (tooltip.caretY < tooltip.height / 2
        ? tooltip.height / 2
        : tooltip.caretY + tooltip.height / 2 > 120
        ? 120 - tooltip.height / 2
        : tooltip.caretY) + "px";
    tooltipEl.style.font = "DM Sans";
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };

  const chartRef = createRef();

  const {
    state: { tempScale },
  } = useContext(SettingsContext);
  const [data, updateData] = useState<Object>({
    labels: [],
    datasets: [
      {
        data: [],
        hoverBackgroundColor: ["#00e000"],
        borderWidth: [0, 0],
      },
    ],
  });
  let options = {};
  if (
    props.type === "PM1.0" ||
    props.type === "PM2.5" ||
    props.type === "PM10"
  ) {
    options = {
      elements: {
        line: {
          borderWidth: 10,
          tension: 0.25,
          fill: true,
        },
        point: {
          backgroundColor: "#00000000",
          radius: 0,
          hoverRadius: 0,
        },
      },
      scales: {
        y: {
          display: false,
          type: "logarithmic",
          suggestedMax: props.range,
          // beginAtZero: true,
          ticks: {
            display: false,
          },
        },
        x: {
          display: false,
          ticks: {
            display: false,
          },
        },
      },
      animation: false,
      normalized: true,
      hover: {
        mode: "index",
        intersect: false,
      },
      plugins: {
        tooltip: {
          enabled: !props.hideTooltip,
          mode: "index",
          intersect: false,
          position: "nearest",
          external: !props.hideTooltip && externalTooltipHandler,
        },
        legend: {
          display: false,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: !props.hideTooltip,
        titleFontFamily: "DM Sans",
        callbacks: {},
      },
    };
  } else {
    let scaling = "linear";
    if (props.type === "vocppm") {
      scaling = "logarithmic";
    }
    options = {
      elements: {
        line: {
          borderWidth: 2,
          tension: 0.25,
          fill: true,
        },
        point: {
          backgroundColor: "#00000000",
          radius: 0,
          hoverRadius: 5,
        },
      },
      scales: {
        y: {
          display: false,
          type: scaling,
          suggestedMax: props.range,
          beginAtZero: true,
          ticks: {
            display: false,
          },
        },
        x: {
          display: false,
          ticks: {
            display: false,
          },
        },
      },
      animation: false,
      hover: {
        mode: "index",
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          mode: "index",
          intersect: false,
          position: "nearest",
          external: externalTooltipHandler,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      // aspectRatio: 2.39,
    };
  }

  useEffect(() => {
    if (props.chartData[0]) {
      let aqiLabels: string[] = [];
      let tooltipTitle: string = "";
      let tooltipValue: string = "0";
      let tooltipLabeledValue: string = "0";
      let aqiData: string[] = [];
      let aqiColors: string[] = [];
      for (let i = 0; i < props.chartData.length; i++) {
        let tempTime = new Date(props.chartData[i].time);
        let aqi = { value: 0, category: "good" };
        let pmValue = 0;
        let pmValues: any = {};
        if (
          props.type === "PM1.0" ||
          props.type === "PM2.5" ||
          props.type === "PM10"
        ) {
          if (props.chartData[i].pm25) {
            pmValues = props.chartData[i];
          } else {
            pmValues = pcToPm(props.chartData[i]);
          }
          if (props.type === "PM1.0") {
            pmValue = pmValues.pm10;
            aqi = pm25Aqi(pmValue);
          }
          if (props.type === "PM2.5") {
            pmValue = pmValues.pm25;
            aqi = pm25Aqi(pmValue);
          }
          if (props.type === "PM10") {
            pmValue = pmValues.pm100;
            aqi = pm100Aqi(pmValue);
          }
          if (props.type !== "voc") {
            if (!tooltipTitle.length) tooltipTitle = t("aqi");
            tooltipValue = Math.round(aqi.value).toString();
          } else {
            // aqiLabels.unshift(tempTime.toLocaleString());
          }
          if (pmValue === 0) {
            pmValue = 0.0;
          }
          if (pmValue) {
            aqiData.unshift(pmValue.toFixed(3));

            tooltipLabeledValue = pmValue.toFixed(3) + " ug/m³";

            aqiColors.unshift(props.colorFunction(aqi.value));
          }
        } else {
          if (props.type === "C" || props.type === "F") {
            if (tempScale === "C") {
              aqiData.unshift(props.chartData[i].temp.toFixed(1));
              if (!tooltipTitle.length) tooltipTitle = "°C";
              tooltipValue = props.chartData[i].temp.toFixed(1);
              tooltipLabeledValue = props.chartData[i].temp.toFixed(1) + " °C";
            } else {
              let cToF = (props.chartData[i].temp * 9) / 5 + 32;
              if (!tooltipTitle.length) tooltipTitle = "°F";
              tooltipValue = cToF.toFixed(1);
              tooltipLabeledValue = cToF.toFixed(1) + " °F";
              if (cToF >= 100) {
                aqiData.unshift(Math.round(cToF).toString());
              } else {
                aqiData.unshift(cToF.toFixed(1));
              }
            }

            aqiColors.unshift(props.colorFunction(props.chartData[i].temp));
          }
          if (props.type === "rh") {
            aqiData.unshift(props.chartData[i].rh.toFixed(1));

            if (!tooltipTitle.length) tooltipTitle = "RH";
            tooltipValue = props.chartData[i].rh.toFixed(1);
            tooltipLabeledValue = props.chartData[i].rh + "%";

            // replace with RH colorFunction
            aqiColors.unshift("#2849ff");
          }
          if (props.type === "atm") {
            aqiData.unshift(props.chartData[i].atm);

            tooltipLabeledValue = props.chartData[i].atm + " hPa";

            // replace with ATM colorFunction
            aqiColors.unshift("rgba(0, 228, 228, 1)");
          }
          if (props.type === "co2") {
            aqiData.unshift(props.chartData[i].co2.toFixed(1));

            if (!tooltipTitle.length) tooltipTitle = "eCO2";
            tooltipValue = props.chartData[i].co2.toFixed(1);
            tooltipLabeledValue = props.chartData[i].co2.toFixed(1) + " ppm";

            aqiColors.unshift(props.colorFunction(props.chartData[i].co2));
          }
          if (props.type === "vocppm") {
            aqiData.unshift(props.chartData[i].vocppm);

            if (!tooltipTitle.length) tooltipTitle = "VOC PPM";
            tooltipValue = props.chartData[i].vocppm.toFixed(1);
            tooltipLabeledValue = props.chartData[i].vocppm.toFixed(1) + " ppm";

            aqiColors.unshift(props.colorFunction(props.chartData[i].vocppm));
          }
          if (props.type === "vocaqi") {
            aqiData.unshift(props.chartData[i].vocaqi);

            if (!tooltipTitle.length) tooltipTitle = t("voc-aqi");
            tooltipValue = props.chartData[i].vocaqi.toString();
            tooltipLabeledValue =
              props.chartData[i].vocaqi.toString() + ` ${t("aqi")}`;

            aqiColors.unshift(props.colorFunction(props.chartData[i].vocaqi));
          }
        }

        let hours = tempTime.getHours();
        let isAM = hours < 12;
        hours = isAM ? hours : hours - 12;
        let minutes = tempTime.getMinutes().toString();
        minutes = parseInt(minutes) < 10 ? "0" + minutes : minutes;
        let seconds = tempTime.getSeconds().toString();
        seconds = parseInt(seconds) < 10 ? "0" + seconds : seconds;
        let tooltipHeader = "";
        if (
          props.type === "PM10" ||
          props.type === "PM2.5" ||
          props.type === "PM1.0"
        ) {
          let level = props.levelFunction(parseFloat(tooltipValue));
          // level = level.charAt(0) + level.slice(1)
          tooltipHeader = `${tooltipTitle}\n${tooltipValue}\n${level}\n`;
        }
        aqiLabels.unshift(
          tooltipHeader +
            `${
              tempTime.getMonth() + 1
            }/${tempTime.getDate()}/${tempTime.getFullYear()},${
              hours ? hours : 12
            }:${minutes}:${seconds} ${isAM ? "am" : "pm"}\n` +
            tooltipLabeledValue
        );
      }
      // @ts-ignore
      updateData((data) => {
        let opacityColors = aqiColors.map((v) => {
          return v;
        });
        opacityColors = opacityColors.map((color) => {
          const _opacity = Math.round(Math.min(Math.max(0.2 || 1, 0), 1) * 255);
          return color + _opacity.toString(16).toUpperCase();
        });
        let borderWidths = new Array(opacityColors.length).fill(3);
        return {
          ...data,
          labels: aqiLabels,
          datasets: [
            {
              data: aqiData,
              borderColor: (context: any) => {
                const chart = context.chart;

                return lineChartGradient(chart, props.type);
              },
              backgroundColor: (context: any) => {
                const chart = context.chart;

                return lineChartGradient(chart, props.type, true);
              },
              borderWidth: borderWidths,
              pointHoverBackgroundColor: aqiColors,
            },
          ],
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempScale, props]);

  return (
    <div className={styles.root}>
      {/* @ts-ignore */}
      <Line ref={chartRef} data={data} options={options} type="line" />
    </div>
  );
}

export default DeviceLineChart;
