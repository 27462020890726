import CSS from "csstype";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import senseiApi from "../../../api/sensei";

import { Context as KitContext } from "../../../context/KitContext";
import { Context as LocationContext } from "../../../context/LocationContext";
import { Context as SensorContext } from "../../../context/SensorContext";
import { Context as SettingsContext } from "../../../context/SettingsContext";
import { Context as TestContext } from "../../../context/TestContext";

import { ModalAction } from "../../../components/BaseModal";

import { paths } from "../../../utils/const";
import { msToTime } from "../../../utils/util";
import { TestEACH } from "../../../utils/types";
import moment from "moment";

function getQueryString(data = {}) {
  return Object.entries(data)
    .map(
      ([key, value]: [key: any, value: any]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
}

export const useConnect = () => {
  const history = useHistory();
  const { t } = useTranslation();
  let { tid } = useParams<{ tid: any | undefined }>();

  const {
    state: { location, site, zone },
    getCurrentLocation,
    getCurrentSite,
    getCurrentZone,
    getLocations,
    // getTests,
    resetState: resetLocationState,
  } = useContext(LocationContext);
  const { getMyKits } = useContext(KitContext);
  const {
    state: sensorState,
    getMyDevices,
    // resetState: resetSensorState,
  } = useContext(SensorContext);
  const { state: settings } = useContext(SettingsContext);
  const {
    state: { rapidTest, tid: currentRapidTest, chartData, isLoading },
    fetchRapidTest,
    fetchChartData,
    getTests,
    setCurrentRapidTest,
    resetState: resetTestState,
  } = useContext(TestContext);

  const [showStopModal, setShowStopModal] = useState(false);

  const returnHome = useCallback(() => {
    resetTestState();
    resetLocationState();
    history.push(paths.home);
  }, [history]);

  const stopRapidTest = useCallback(async () => {
    let data = {
      tid: tid,
    };
    await senseiApi.post(`/stop-each`, getQueryString(data), {
      withCredentials: true,
      maxRedirects: 0,
    });
    setShowDiffuseModal(undefined);
    setShowStopModal(false);
    history.push(paths.home);
    getMyKits();
    getMyDevices();
    getTests("demo");
    resetLocationState();
    resetTestState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMyKits, getMyDevices, getTests, history, showStopModal, tid]);

  const restartRapidTest = useCallback(async () => {
    if (rapidTest?.status === "error") {
      let data = {
        location: rapidTest?.zone,
        did0: rapidTest.did0 ?? -1,
        did1: rapidTest.did1 ?? -1,
        did2: rapidTest.did2 ?? -1,
        did3: rapidTest.did3 ?? -1,
        diffuser: rapidTest.diffuser ? rapidTest.diffuser : undefined,
        email: rapidTest.email,
        hvac: rapidTest.hvac,
        interventions:
          "interventions" in rapidTest ? rapidTest.interventions : undefined,
        cadr: "cadr" in rapidTest ? rapidTest.cadr : undefined,
        configuration:
          "configuration" in rapidTest ? rapidTest.configuration : undefined,
        notes: rapidTest.notes,
        oneclick: rapidTest.diffuser ? 1 : 0,
        bkgd_time: rapidTest.diffuser ? 5 * 60 : undefined,
        diff_time: 5 * 60,
        sett_time: 3 * 60,
        decay_time: 12 * 60,
      };
      let res = await senseiApi.post("/start-each", getQueryString(data), {
        withCredentials: true,
        maxRedirects: 0,
      });
      setShowDiffuseModal(undefined);
      setShowStopModal(false);
      history.push(`/demo/${res?.data?.id}`);
      setCurrentRapidTest(res?.data?.id);
      getTests(settings.testType);
    }
  }, [rapidTest, history, getTests, setCurrentRapidTest]);

  useEffect(() => {
    if (rapidTest?.zone && zone?.zid !== rapidTest?.zone) {
      getCurrentZone(rapidTest.zone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rapidTest, zone?.zid]);

  // 15sec interal: fetch eACH test result && chart data
  useEffect(() => {
    let isMounted = true;

    const tick = async () => {
      if (isMounted) {
        if (tid > 0) {
          if (currentRapidTest !== tid) {
            setCurrentRapidTest(tid);
            fetchRapidTest(tid, "demo");
          }
          if (
            currentRapidTest === tid &&
            rapidTest.status !== "success" &&
            rapidTest.status !== "error"
          ) {
            fetchRapidTest(tid, "demo");
          }
          if (rapidTest.status !== "success" && sensorState.devices[0]?.did) {
            fetchChartData(sensorState.devices[0]?.did);
          }
        } else returnHome();
      }
    };
    let tickInt = setInterval(tick, 15 * 1000);

    tick();

    return () => {
      isMounted = false;
      clearInterval(tickInt);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid, sensorState.devices, rapidTest.status]);

  useEffect(() => {
    const fetchSite = async () => {
      if (zone?.site && (!site || site.sid !== zone.site))
        getCurrentSite(zone?.site);
    };
    const fetchLocation = async () => {
      if (site?.location && (!location || location.lid !== site?.location))
        getCurrentLocation(site?.location);
    };
    fetchSite();
    fetchLocation();
  }, [location, site, zone?.site]);

  const [notes, setNotes] = useState(rapidTest?.notes);
  const [notesIsLoading, setNotesIsLoading] = useState(false);

  const handleNotesChange = (event: any) => {
    if (event.target.value.length < 256) setNotes(event.target.value);
  };

  const submitNotes = useCallback(async () => {
    if (notes !== rapidTest.notes) {
      setNotesIsLoading(true);
      let data = {
        notes: notes,
      };
      await senseiApi.put(`/get-each-tests/${tid}`, getQueryString(data), {
        withCredentials: true,
        maxRedirects: 0,
      });
      await fetchRapidTest(tid, "demo");
      setNotesIsLoading(false);
    }
  }, [fetchRapidTest, notes, rapidTest.notes, tid]);

  useEffect(() => {
    if (rapidTest?.notes !== notes) {
      setNotes(rapidTest?.notes);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rapidTest?.notes]);

  const notesDisabledSubmit = useMemo(() => {
    return !!(notes === rapidTest.notes || notesIsLoading);
  }, [notes, notesIsLoading, rapidTest.notes]);

  const errorModalActions = useMemo<
    ModalAction | ModalAction[] | undefined
  >(() => {
    if (rapidTest.status !== "error") return undefined;
    if (rapidTest.errorcode === 112 || rapidTest.errorcode === 113)
      return [
        {
          onClick: restartRapidTest,
          type: "default",
          text: t("modal.button.reRunTest"),
        },
        {
          onClick: returnHome,
          type: "submit",
          text: t("modal.button.returnHome"),
        },
      ];
    return {
      onClick: returnHome,
      type: "danger",
      text: t("modal.button.returnHome"),
    };
  }, [rapidTest.errorcode, rapidTest.status, restartRapidTest, returnHome, t]);

  const [testStatus, setTestStatus] = useState<string>("loading");

  useEffect(() => {
    const tick = () => {
      const test = rapidTest as TestEACH;
      const testTime = new Date(test.start);
      const timeElasped = new Date().getTime() - testTime.getTime();
      if (test.status === "error" || test.status === "success") {
        setTestStatus(test.status);
        return;
      }
      if (test.status === "running") {
        if (timeElasped < test.bkgd_time * 1000) {
          setTestStatus("background");
        } else if (
          timeElasped <
          test.bkgd_time * 1000 + test.diff_time * 1000
        ) {
          setTestStatus("diffusion");
        } else if (
          timeElasped <
          test.bkgd_time * 1000 + test.diff_time * 1000 + test.sett_time * 1000
        ) {
          setTestStatus("settling");
        } else setTestStatus("measuring");
        return;
      } else setTestStatus("loading");
    };

    let tickInterval = setInterval(tick, 1000);

    tick();

    return () => {
      clearInterval(tickInterval);
    };
  }, [rapidTest.status]);

  const [rapidTestTimeRemaining, setRapidTestTimeRemaining] = useState<
    string | undefined
  >(undefined);
  // 1s interval: tick rapidTestTimeRemaining
  useEffect(() => {
    let intervalFunction = setInterval(async () => {
      if (rapidTest.start) {
        // Use 20 minutes plus 14 seconds to allow for delay in API response
        let msRemaining = 0; // 1800000 // 25000
        let test = rapidTest as TestEACH;
        const testTime = new Date(rapidTest.start);
        const bkgdEndMoment = moment(testTime).add(test.bkgd_time, "seconds");
        const bkgdEndTime = bkgdEndMoment.toDate();
        const diffEndMoment = moment(bkgdEndTime).add(
          test.diff_time,
          "seconds"
        );
        const diffEndTime = diffEndMoment.toDate();
        const settEndMoment = moment(diffEndTime).add(
          test.sett_time,
          "seconds"
        );
        const settEndTime = settEndMoment.toDate();

        switch (testStatus) {
          case "background":
            msRemaining = bkgdEndTime.getTime() - new Date().getTime();
            break;
          case "diffusion":
            // this is actually timeElapsed
            msRemaining = new Date().getTime() - bkgdEndTime.getTime();
            break;
          case "settling":
            msRemaining = settEndTime.getTime() - new Date().getTime();
            break;
          case "measuring":
            // this is actually timeElapsed
            msRemaining = new Date().getTime() - settEndTime.getTime();
            break;
          default:
            break;
        }
        if (msRemaining > 0) {
          setRapidTestTimeRemaining(msToTime(msRemaining));
        } else {
          setRapidTestTimeRemaining("00:00");
          clearInterval(intervalFunction);
        }
      }
    }, 1000);
    return () => {
      clearInterval(intervalFunction);
    };
  }, [tid, rapidTestTimeRemaining, rapidTest]);

  const [showDiffuseModal, setShowDiffuseModal] = useState<
    undefined | "start" | "stop" | "hide"
  >(undefined);

  useEffect(() => {
    if (rapidTest.oneclick === 0 && rapidTest.status === "running") {
      let now = new Date().getTime();
      let startTime = new Date(rapidTest.start).getTime();
      let backgroundEndTime =
        startTime + (rapidTest as TestEACH).bkgd_time * 1000;
      let diffusionEndTime =
        backgroundEndTime + (rapidTest as TestEACH).diff_time * 1000;

      if (showDiffuseModal === undefined || showDiffuseModal === "hide") {
        if (
          showDiffuseModal === undefined &&
          testStatus === "background" &&
          now + 60 * 1000 > backgroundEndTime
        ) {
          setShowDiffuseModal("start");
        } else if (
          testStatus === "diffusion" &&
          now + 60 * 1000 > diffusionEndTime
        ) {
          setShowDiffuseModal("stop");
        }
      }
    }
  }, [rapidTestTimeRemaining, rapidTest, showDiffuseModal]);

  // cleanup because this is gross to look at
  const [buttonText, setButtonText] = useState("");
  useEffect(() => {
    let now = new Date().getTime();
    let text = "";
    switch (testStatus) {
      case "background":
        text = t("measuring-background");
        break;
      case "diffusion":
        text = t("measuring");
        break;
      case "settling":
        text = t("settling");
        break;
      case "measuring":
        text = t("measuring");
        break;
      default:
        break;
    }
    if (rapidTest.status === "success") setButtonText(t("button.returnHome"));
    else if (rapidTest.status === "error")
      setButtonText(t("button.returnHome"));
    else setButtonText(`${text}${".".repeat(((now / 1000) % 3) + 1)}`);
  }, [rapidTest, t, testStatus, rapidTestTimeRemaining]);

  const kitDevices = useMemo(
    () =>
      sensorState.allDevices
        .filter((item) =>
          [
            rapidTest?.did0,
            rapidTest?.did1,
            rapidTest?.did2,
            rapidTest?.did3,
          ].includes(item.did)
        )
        .sort((a, b) => a.did - b.did),
    [sensorState.allDevices, rapidTest]
  );

  return {
    chartData,
    allDevices: sensorState.allDevices,
    devices: sensorState.devices,
    kitDevices: kitDevices,
    kitPlusDevices: sensorState.kitPlusDevices,
    location,
    site,
    zone,
    rapidTest,
    sensorState,
    hideEach: settings.hideEach,
    buttonText,
    stopRapidTest,
    toggleStopModal: () => setShowStopModal(!showStopModal),
    showStopModal,
    errorModalActions,
    restartRapidTest,
    returnHome,
    notes,
    handleNotesChange,
    submitNotes,
    notesDisabledSubmit,
    rapidTestTimeRemaining,
    testStatus,
    showDiffuseModal,
    closeDiffuserModal: () => setShowDiffuseModal("hide"),
  };
};
