export const paths = {
  root: "/",
  auth: "/auth",
  login: "/auth/login",
  signup: "/auth/signup",
  setNewPassword: "/auth/set-new-password",
  setNewPasswordToken: "/auth/set-new-password/:token?",
  claimCode: "/claim-code/:claimCode",
  dashboard: "/dashboard",
  details: "/device/:did",
  profile: "/profile",
  account: "/account",
  settings: "/settings",
  support: "https://www.poppy.com/contact",
  home: "/home",
  newLocation: "/location/new",
  // rapidTest: "/rapid-test/:tid",
  test241: "/test/:tid",
  each: "/each/:tid",
  demo: "/demo/:tid",
  conditionsInfo: "/test/conditions-info",
  // this is not a lid, but a zid. page is just called /location.
  location: "/location/:lid",
};

export const MS_BACKGROUND = 300000;
export const MS_DIFFUSION = 840000;
export const MS_SETTLING = 360000;
export const MS_COMPLETE = 300000;

export const MS_NEBULIZING_END = 300000;
export const MS_NEBULIZING_LENGTH = 300000;
export const MS_COLLECTING_END = MS_NEBULIZING_END + 180000;
export const MS_COLLECTING_LENGTH = 180000;
export const MS_DECAY_END = MS_COLLECTING_END + 720000;
export const MS_DECAY_LENGTH = 720000;
