import createDataContext from "./createDataContext";
import senseiApi from "../api/sensei";
import {
  Action,
  Conditions,
  Test241,
  TestEACH,
  TestType,
} from "../utils/types";

interface TestState {
  tid: number;
  rapidTest: Test241 | TestEACH;
  chartData: [];
  rapidTestSensorData: [];
  isLoading: boolean;
  start: boolean;
  // move to RapidTest context
  conditionsInfo: Conditions;
  testStatus: "" | "running" | "success" | "error";
  tests: Array<Test241 | TestEACH>;
}

interface TestContext {
  state: TestState;
  fetchChartData: (did: number, time?: string) => void;
  fetchRapidTest: (tid: number, testType: TestType) => void;
  setCurrentRapidTest: (tid: number) => void;
  setIsLoading: (loading: boolean) => void;
  setConditionsInfo: (conditions: Conditions) => void;
  getTests: (testType: TestType) => void;
  toggleStart: (toggle?: boolean) => void;
  resetState: () => void;
  updateTestNextStep: (tid: number) => void;
}

function getQueryString(data = {}) {
  return Object.entries(data)
    .map(
      ([key, value]: [key: any, value: any]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
}

const testReducer = (state: TestState, action: Action) => {
  // console.log(`${action.type}: `, action.payload);
  switch (action.type) {
    case "set-test-id":
      return { ...state, tid: action.payload };
    case "set-rapid-test":
      return { ...state, rapidTest: action.payload };
    case "fetch-chart-data":
      return { ...state, chartData: action.payload };
    case "set-rapid-test-sensor-data":
      return { ...state, rapidTestSensorData: action.payload };
    case "set-tests":
      return { ...state, tests: action.payload };
    case "set-is-loading":
      return { ...state, isLoading: action.payload };
    case "toggle-start":
      return {
        ...state,
        start:
          action.payload !== undefined
            ? action.payload
            : state.start
            ? false
            : true,
      };
    case "set-conditions-info":
      return { ...state, conditionsInfo: action.payload };
    case "reset-state":
      return {
        ...state,
        tid: -1,
        rapidTest: {} as Test241 | TestEACH,
        chartData: [],
        rapidTestSensorData: [],
        start: false,
        isLoading: false,
        conditionsInfo: {
          email: "",
          interventions: "",
          cadr: "",
          hvac: -1,
          notes: "",
          occupancy: -1,
        },
        // tests: [],
        testStatus: "",
      };
    default:
      return state;
  }
};

const setCurrentRapidTest = (dispatch: any) => async (tid: string) => {
  try {
    dispatch({ type: "set-test-id", payload: tid });
  } catch (error: any) {}
};

const fetchChartData =
  (dispatch: any) => async (did: number, time?: string) => {
    try {
      if (did > 0) {
        let response;
        if (!time)
          response = await senseiApi.get(
            `get-minute-averages/${did}?page=${0}`,
            { withCredentials: true }
          );
        else
          response = await senseiApi.get(
            `get-minute-averages/${did}?page=${0}&start=${time}`,
            { withCredentials: true }
          );
        if (response.data?.data?.length) {
          dispatch({
            type: "fetch-chart-data",
            payload: response.data.data,
          });
        }
      }
    } catch (error: any) {}
  };

const fetchRapidTest =
  (dispatch: any) => async (tid: number, testType: TestType) => {
    try {
      if (tid > 0) {
        let response: any;
        if (testType === "241") {
          response = await senseiApi.get(`tests/${tid}`, {
            withCredentials: true,
          });
          dispatch({
            type: "set-rapid-test",
            payload: {
              ...response?.data?.data[0],
            },
          });
          return;
        } else if (testType === "each") {
          response = await senseiApi.get(`get-each-test/${tid}`, {
            withCredentials: true,
          });
          dispatch({
            type: "set-rapid-test",
            payload: {
              ...response?.data?.test,
            },
          });
          dispatch({
            type: "set-rapid-test-sensor-data",
            payload: [
              ...response?.data?.data.sort(
                (a: any, b: any) =>
                  new Date(b.time).getTime() - new Date(a.time).getTime()
              ),
            ],
          });
          return;
        } else if (testType === "demo") {
          response = await senseiApi.get(`get-each-test/${tid}`, {
            withCredentials: true,
          });
          dispatch({
            type: "set-rapid-test",
            payload: {
              ...response?.data?.test,
            },
          });
          return;
        } else {
        }
      }
    } catch (error: any) {}
  };

const updateTestNextStep = (dispatch: any) => async (tid: number) => {
  if (true) {
    dispatch({
      type: "set-is-loading",
      payload: true,
    });
    await senseiApi.put(`/tests/${tid}`, getQueryString({ next: true }), {
      withCredentials: true,
    });
    dispatch({
      type: "set-is-loading",
      payload: false,
    });
  }
};

const setIsLoading = (dispatch: any) => async (loading: true) => {
  try {
    dispatch({ type: "set-test-id", payload: loading });
  } catch (error: any) {}
};

const getTests = (dispatch: Function) => async (testType: TestType) => {
  try {
    let response: any;
    if (testType === "241") {
      response = await senseiApi.get(`/tests`, {
        withCredentials: true,
      });
      dispatch({
        type: "set-tests",
        payload: response.data.data as Test241[],
      });
    } else if (testType === "each") {
      response = await senseiApi.get("/get-each-tests", {
        withCredentials: true,
      });
      let data = response.data.data.filter(
        (test: TestEACH) => test.configuration > -1
      );
      dispatch({
        type: "set-tests",
        payload: data as TestEACH[],
      });
    } else if (testType === "demo") {
      response = await senseiApi.get("/get-each-tests", {
        withCredentials: true,
      });
      let data = response.data.data.filter(
        (test: TestEACH) => test.configuration === -1
      );
      dispatch({
        type: "set-tests",
        payload: data,
      });
    }
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const setConditionsInfo = (dispatch: Function) => (payload: Conditions) => {
  try {
    dispatch({ type: "set-conditions-info", payload: payload });
  } catch (error) {}
};

const toggleStart = (dispatch: Function) => (payload?: boolean) => {
  try {
    dispatch({ type: "toggle-start", payload: payload });
  } catch (error) {}
};

const resetState = (dispatch: any) => () => {
  dispatch({ type: "reset-state" });
};

export const {
  Provider,
  Context,
}: {
  Provider: ({ children }: { children: any }) => JSX.Element;
  Context: React.Context<TestContext>;
} = createDataContext(
  testReducer,
  {
    fetchChartData,
    fetchRapidTest,
    setCurrentRapidTest,
    setIsLoading,
    getTests,
    setConditionsInfo,
    updateTestNextStep,
    toggleStart,
    resetState,
  },
  {
    tid: -1,
    rapidTest: {} as Test241 | TestEACH,
    chartData: [],
    rapidTestSensorData: [],
    isLoading: false,
    start: false,

    conditionsInfo: {
      email: "",
      interventions: "",
      cadr: "",
      hvac: -1,
      notes: "",
    },
    tests: [],
    testStatus: "",
  } as TestState
);
