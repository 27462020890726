import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Sensor, TestEACH } from "../../utils/types";

import styles from "./sensorMeasurements.module.scss";
import { calcResultAvg } from "../../utils/util";

type SensorMeasurement = {
  name: string;
  ecai: string;
  each: string;
  isMax: boolean;
};

function SensorMeasurements({
  test,
  devices,
  sensorData,
  peak,
  volume,
}: {
  test: TestEACH;
  devices: Sensor[];
  sensorData: any;
  peak: number;
  volume: number;
}) {
  const { t } = useTranslation();

  const [measurements, setMeasurements] = useState<SensorMeasurement[]>();
  const [eachAvg, setEachAvg] = useState<number>();

  useEffect(() => {
    if (!!test.status && sensorData.length > 0) {
      let eoa0 = "-",
        eoa1 = "-",
        eoa2 = "-";
      // eoa3 = "-";
      let each0 = "-",
        each1 = "-",
        each2 = "-";
      // each3 = "-";
      let maxIndex = -1;
      let max = -1;

      if (test.each0 > 0) {
        eoa0 = ((test.each0 * volume) / 60 / peak).toFixed(1);
        each0 = test.each0.toFixed(1);
        if (test.each0 > max) {
          max = test.each0;
          maxIndex = 0;
        }
      }
      if (test.each1 > 0) {
        eoa1 = ((test.each1 * volume) / 60 / peak).toFixed(1);
        each1 = test.each1.toFixed(1);
        if (test.each1 > max) {
          max = test.each1;
          maxIndex = 1;
        }
      }
      if (test.each2 > 0) {
        eoa2 = ((test.each2 * volume) / 60 / peak).toFixed(1);
        each2 = test.each2.toFixed(1);
        if (test.each2 > max) {
          max = test.each2;
          maxIndex = 2;
        }
      }
      // if (test.each3 > 0) {
      //   eoa3 = ((test.each3 * volume) / 60 / peak).toFixed(1);
      //   each3 = test.each3.toFixed(1);
      //   if (test.each3 > max) {
      //     max = test.each3;
      //     maxIndex = 3;
      //   }
      // }
      let tempMeasurements = [
        {
          name:
            devices?.find((device: any) => device.did === test.did0)?.iotid ??
            `Device ID: ${test.did0 > -1 ? test.did0 : "N/A"}`,
          ecai: eoa0,
          each: each0,
          isMax: maxIndex === 0,
        },
        {
          name:
            devices?.find((device: any) => device.did === test.did1)?.iotid ??
            `Device ID: ${test.did1 > -1 ? test.did1 : "N/A"}`,
          ecai: eoa1,
          each: each1,
          isMax: maxIndex === 1,
        },
        {
          name:
            devices?.find((device: any) => device.did === test.did2)?.iotid ??
            `Device ID: ${test.did2 > -1 ? test.did2 : "N/A"}`,
          ecai: eoa2,
          each: each2,
          isMax: maxIndex === 2,
        },
        // {
        //   name:
        //     devices?.find((device) => device.did === test.did3)?.iotid ??
        //     `Device ID: ${test.did3 > -1 ? test.did3 : "N/A"}`,
        //   ecai: eoa3,
        //   each: each3,
        //   isMax: maxIndex === 3,
        // },
      ].filter((d) => d.each !== "-");

      setEachAvg(calcResultAvg(test));
      setMeasurements(tempMeasurements);
    }
    return () => {
      setEachAvg(undefined);
      setMeasurements([]);
    };
  }, [test, sensorData, devices, peak, volume]);

  if (!!measurements?.length)
    return (
      <div className={styles.root}>
        <h2 className={styles.header}>{t("sensor-measurements")}</h2>
        <div className={styles.columnsHeader}>
          <span className={styles.columnLabel}>{t("sensor")}</span>
          <span className={styles.columnLabel}>
            {t("eca-label")}
            <sub>{t("i")}</sub>
          </span>
          <span className={styles.columnLabel}>{t("each-label")}</span>
        </div>
        <div className={styles.sensors}>
          <span className={styles.measurements} key="average">
            <p>Average</p>
            <p>{eachAvg ? ((eachAvg * volume) / 60 / peak).toFixed(1) : ""}</p>
            <p>{eachAvg?.toFixed(1)}</p>
          </span>
          {measurements.map((sensor, i) => (
            <span className={styles.measurements} key={`${sensor.name}-${i}`}>
              <p>{sensor.name}</p>
              <p className={sensor.isMax ? styles.isMax : ""}>{sensor.ecai}</p>
              <p className={sensor.isMax ? styles.isMax : ""}>{sensor.each}</p>
            </span>
          ))}
        </div>
      </div>
    );
  else return <></>;
}

export default SensorMeasurements;
