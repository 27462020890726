import { Location, Site, Zone } from "../../utils/types";

import styles from "./testLocationInfo.module.scss";

type TestLocationInfoProps = {
  location: Location | null | undefined;
  site: Site | null | undefined;
  zone: Zone | null | undefined;
};

function TestLocationInfo({ location, site, zone }: TestLocationInfoProps) {
  return (
    <div className={styles.root}>
      <h2 className={location?.name ?? styles.notFound}>
        {location?.name ?? "-"}
      </h2>
      <p className={`${styles.locationInfo} ${site?.name ?? styles.notFound}`}>
        <b>{site?.name ?? "-"}</b>
      </p>
      |
      <p className={`${styles.locationInfo} ${zone?.name ?? styles.notFound}`}>
        <b>{zone?.name ?? "-"}</b>
      </p>
    </div>
  );
}

export default TestLocationInfo;
