// Cubic feet to meters
// const cfToM3 = 35.315;

// Liters to cubic meters
const lToM3 = 1000;

// Constants for converting particle counts to PM
const pmConversions = {
  pm01: 8.3556e-10 * 1,
  pm03: 2.256e-8 * 1,
  pm05: 1.0444e-7 * 1,
  pm10: 8.3556e-7 * 1,
  pm25: 1.306e-5 * 0.33,
  pm50: 1.044e-4 * 0.5,
  pm100: 8.3556e-4 * 0.5,
};

// Buffers for raw values
let bufferLength = 100;
let pc01Buff: number[] = [];
let pc03Buff: number[] = [];
let pc05Buff: number[] = [];
let pc10Buff: number[] = [];
let pc25Buff: number[] = [];
let pc50Buff: number[] = [];
let pc100Buff: number[] = [];
let tempBuff: number[] = [];
let rhBuff: number[] = [];
let vocBuff: number[] = [];
let pressBuff: number[] = [];
let timestampBuff: number[] = [];

interface Ratios {
  pc0103: number[];
  pc0305: number[];
  pc0510: number[];
  pc1025: number[];
  pc2550: number[];
  pc50100: number[];
}

//let ratioBuff = [];

// function processDataBuffer(pcData: any) {
//   timestampBuff = addToBuffer(Date.now(), timestampBuff);
//   pc01Buff = addToBuffer(pcData.pc01, pc01Buff);
//   pc03Buff = addToBuffer(pcData.pc03, pc03Buff);
//   pc05Buff = addToBuffer(pcData.pc05, pc05Buff);
//   pc10Buff = addToBuffer(pcData.pc10, pc10Buff);
//   pc25Buff = addToBuffer(pcData.pc25, pc25Buff);
//   pc50Buff = addToBuffer(pcData.pc50, pc50Buff);
//   pc100Buff = addToBuffer(pcData.pc100, pc100Buff);

//   return { pc01Buff, pc03Buff, pc05Buff, pc10Buff, pc25Buff, pc50Buff, pc100Buff, timestampBuff };
// }

function processDataBuffer(
  pcData: any,
  temp = -274,
  rh = -1,
  voc = -1,
  press = -1
) {
  timestampBuff = addToBuffer(Date.now(), timestampBuff);
  pc01Buff = addToBuffer(pcData.pc01, pc01Buff);
  pc03Buff = addToBuffer(pcData.pc03, pc03Buff);
  pc05Buff = addToBuffer(pcData.pc05, pc05Buff);
  pc10Buff = addToBuffer(pcData.pc10, pc10Buff);
  pc25Buff = addToBuffer(pcData.pc25, pc25Buff);
  pc50Buff = addToBuffer(pcData.pc50, pc50Buff);
  pc100Buff = addToBuffer(pcData.pc100, pc100Buff);
  tempBuff = addToBuffer(temp, tempBuff);
  rhBuff = addToBuffer(rh, rhBuff);
  vocBuff = addToBuffer(voc, vocBuff);
  pressBuff = addToBuffer(press, pressBuff);

  return {
    pc01Buff,
    pc03Buff,
    pc05Buff,
    pc10Buff,
    pc25Buff,
    pc50Buff,
    pc100Buff,
    tempBuff,
    rhBuff,
    vocBuff,
    pressBuff,
    timestampBuff,
  };
}

function getTimestamps() {
  return timestampBuff;
}

function getCounts() {
  return [
    pc01Buff,
    pc03Buff,
    pc05Buff,
    pc10Buff,
    pc25Buff,
    pc50Buff,
    pc100Buff,
  ];
}

function getPCs(chartData: any[]) {
  let pc01 = [];
  let pc03 = [];
  let pc05 = [];
  let pc10 = [];
  let pc25 = [];
  let pc50 = [];
  let pc100 = [];
  let labels = [];
  for (let i = 0; i < chartData.length; i++) {
    pc01.unshift(chartData[i].pc01);
    pc03.unshift(chartData[i].pc03);
    pc05.unshift(chartData[i].pc05);
    pc10.unshift(chartData[i].pc10);
    pc25.unshift(chartData[i].pc25);
    pc50.unshift(chartData[i].pc50);
    pc100.unshift(chartData[i].pc100);
    let time = new Date(chartData[i].time);
    labels.unshift("TIME: " + time.toLocaleString());
  }
  return { values: [pc01, pc03, pc05, pc10, pc25, pc50, pc100], labels };
}

function getPMs(chartData: any[]) {
  let pm01 = [];
  let pm03 = [];
  let pm05 = [];
  let pm10 = [];
  let pm25 = [];
  let pm50 = [];
  let pm100 = [];
  let labels = [];
  for (let i = 0; i < chartData.length; i++) {
    // let pmValues = pcToPm(chartData[i]);
    // pm01.unshift(pmValues.pm01);
    // pm03.unshift(pmValues.pm03);
    // pm05.unshift(pmValues.pm05);
    // pm10.unshift(pmValues.pm10);
    // pm25.unshift(pmValues.pm25);
    // pm50.unshift(pmValues.pm50);
    // pm100.unshift(pmValues.pm100);
    pm01.unshift(chartData[i].pm01);
    pm03.unshift(chartData[i].pm03);
    pm05.unshift(chartData[i].pm05);
    pm10.unshift(chartData[i].pm10);
    pm25.unshift(chartData[i].pm25);
    pm50.unshift(chartData[i].pm50);
    pm100.unshift(chartData[i].pm100);
    let time = new Date(chartData[i].time);
    labels.unshift("TIME: " + time.toLocaleString());
  }
  return { values: [pm01, pm03, pm05, pm10, pm25, pm50, pm100], labels };
}

function getRatios(chartData: any[]) {
  let ratios: Ratios = {
    pc0103: [],
    pc0305: [],
    pc0510: [],
    pc1025: [],
    pc2550: [],
    pc50100: [],
  };
  let labels = [];
  // = {
  //   pc0103: [],
  //   pc0305: [],
  //   pc0510: [],
  //   pc1025: [],
  //   pc2550: [],
  //   pc50100: []
  // };
  for (let i = 0; i < chartData.length; i++) {
    ratios.pc0103.unshift(
      chartData[i].pc01 / chartData[i].pc03 === Infinity
        ? 0
        : chartData[i].pc01 / chartData[i].pc03
    );
    ratios.pc0305.unshift(
      chartData[i].pc03 / chartData[i].pc05 === Infinity
        ? 0
        : chartData[i].pc03 / chartData[i].pc05
    );
    ratios.pc0510.unshift(
      chartData[i].pc05 / chartData[i].pc10 === Infinity
        ? 0
        : chartData[i].pc05 / chartData[i].pc10
    );
    ratios.pc1025.unshift(
      chartData[i].pc10 / chartData[i].pc25 === Infinity
        ? 0
        : chartData[i].pc10 / chartData[i].pc25
    );
    ratios.pc2550.unshift(
      chartData[i].pc25 / chartData[i].pc50 === Infinity
        ? 0
        : chartData[i].pc25 / chartData[i].pc50
    );
    ratios.pc50100.unshift(
      chartData[i].pc50 / chartData[i].pc100 === Infinity
        ? 0
        : chartData[i].pc50 / chartData[i].pc100
    );
    let time = new Date(chartData[i].time);
    labels.unshift("TIME: " + time.toLocaleString());
  }
  return {
    values: [
      ratios.pc0103,
      ratios.pc0305,
      ratios.pc0510,
      ratios.pc1025,
      ratios.pc2550,
      ratios.pc50100,
    ],
    labels,
  };
}

function getRelativeRatios(comparison: any) {
  let ratios: Ratios = {
    pc0103: [],
    pc0305: [],
    pc0510: [],
    pc1025: [],
    pc2550: [],
    pc50100: [],
  };
  // let ratios = {
  //   pc0103: [],
  //   pc0305: [],
  //   pc0510: [],
  //   pc1025: [],
  //   pc2550: [],
  //   pc50100: []
  // };
  for (let i = 0; i < pc01Buff.length; i++) {
    ratios.pc0103.unshift(
      pc01Buff[i] / pc03Buff[i] / (comparison.pc01 / comparison.pc03)
    );
    ratios.pc0305.unshift(
      pc03Buff[i] / pc05Buff[i] / (comparison.pc03 / comparison.pc05)
    );
    ratios.pc0510.unshift(
      pc05Buff[i] / pc10Buff[i] / (comparison.pc05 / comparison.pc10)
    );
    ratios.pc1025.unshift(
      pc10Buff[i] / pc25Buff[i] / (comparison.pc10 / comparison.pc25)
    );
    ratios.pc2550.unshift(
      pc25Buff[i] / pc50Buff[i] / (comparison.pc25 / comparison.pc50)
    );
    ratios.pc50100.unshift(
      pc50Buff[i] / pc100Buff[i] / (comparison.pc50 / comparison.pc100)
    );
  }
  return [
    ratios.pc0103,
    ratios.pc0305,
    ratios.pc0510,
    ratios.pc1025,
    ratios.pc2550,
    ratios.pc50100,
  ];
}

function getPercentageByCount(chartData: any[]) {
  let perc01 = [];
  let perc03 = [];
  let perc05 = [];
  let perc10 = [];
  let perc25 = [];
  let perc50 = [];
  let perc100 = [];
  let labels = [];
  for (let i = 0; i < chartData.length; i++) {
    let total =
      chartData[i].pc01 +
      chartData[i].pc03 +
      chartData[i].pc05 +
      chartData[i].pc10 +
      chartData[i].pc25 +
      chartData[i].pc50 +
      chartData[i].pc100;
    perc01.unshift((chartData[i].pc01 / total) * 100);
    perc03.unshift((chartData[i].pc03 / total) * 100);
    perc05.unshift((chartData[i].pc05 / total) * 100);
    perc10.unshift((chartData[i].pc10 / total) * 100);
    perc25.unshift((chartData[i].pc25 / total) * 100);
    perc50.unshift((chartData[i].pc50 / total) * 100);
    perc100.unshift((chartData[i].pc100 / total) * 100);
    let time = new Date(chartData[i].time);
    labels.unshift("TIME: " + time.toLocaleString());
  }
  return {
    values: [perc01, perc03, perc05, perc10, perc25, perc50, perc100],
    labels,
  };
}

function getPercentageByCountRelative(comparison: any) {
  let perc01 = [];
  let perc03 = [];
  let perc05 = [];
  let perc10 = [];
  let perc25 = [];
  let perc50 = [];
  let perc100 = [];
  let compTotal =
    comparison.pc01 +
    comparison.pc03 +
    comparison.pc05 +
    comparison.pc10 +
    comparison.pc25 +
    comparison.pc50 +
    comparison.pc100;
  for (let i = 0; i < pc01Buff.length; i++) {
    let total =
      pc01Buff[i] +
      pc03Buff[i] +
      pc05Buff[i] +
      pc10Buff[i] +
      pc25Buff[i] +
      pc50Buff[i] +
      pc100Buff[i];
    perc01.unshift(pc01Buff[i] / total / (comparison.pc01 / compTotal));
    perc03.unshift(pc03Buff[i] / total / (comparison.pc03 / compTotal));
    perc05.unshift(pc05Buff[i] / total / (comparison.pc05 / compTotal));
    perc10.unshift(pc10Buff[i] / total / (comparison.pc10 / compTotal));
    perc25.unshift(pc25Buff[i] / total / (comparison.pc25 / compTotal));
    perc50.unshift(pc50Buff[i] / total / (comparison.pc50 / compTotal));
    perc100.unshift(pc100Buff[i] / total / (comparison.pc100 / compTotal));
  }
  return [perc01, perc03, perc05, perc10, perc25, perc50, perc100];
}

function getPercentageByMass(chartData: any[]) {
  let perc01 = [];
  let perc03 = [];
  let perc05 = [];
  let perc10 = [];
  let perc25 = [];
  let perc50 = [];
  let perc100 = [];
  let labels = [];
  for (let i = 0; i < chartData.length; i++) {
    let mass01 = chartData[i].pc01 * pmConversions.pm01;
    let mass03 = chartData[i].pc03 * pmConversions.pm03;
    let mass05 = chartData[i].pc05 * pmConversions.pm05;
    let mass10 = chartData[i].pc10 * pmConversions.pm10;
    let mass25 = chartData[i].pc25 * pmConversions.pm25;
    let mass50 = chartData[i].pc50 * pmConversions.pm50;
    let mass100 = chartData[i].pc100 * pmConversions.pm100;
    let total = mass01 + mass03 + mass05 + mass10 + mass25 + mass50 + mass100;
    perc01.unshift((mass01 / total) * 100);
    perc03.unshift((mass03 / total) * 100);
    perc05.unshift((mass05 / total) * 100);
    perc10.unshift((mass10 / total) * 100);
    perc25.unshift((mass25 / total) * 100);
    perc50.unshift((mass50 / total) * 100);
    perc100.unshift((mass100 / total) * 100);
    let time = new Date(chartData[i].time);
    labels.unshift("TIME: " + time.toLocaleString());
  }
  return {
    values: [perc01, perc03, perc05, perc10, perc25, perc50, perc100],
    labels,
  };
}

function getPercentageByMassRelative(comparison: any) {
  let perc01 = [];
  let perc03 = [];
  let perc05 = [];
  let perc10 = [];
  let perc25 = [];
  let perc50 = [];
  let perc100 = [];
  let compMass01 = comparison.pc01 * pmConversions.pm01;
  let compMass03 = comparison.pc03 * pmConversions.pm03;
  let compMass05 = comparison.pc05 * pmConversions.pm05;
  let compMass10 = comparison.pc10 * pmConversions.pm10;
  let compMass25 = comparison.pc25 * pmConversions.pm25;
  let compMass50 = comparison.pc50 * pmConversions.pm50;
  let compMass100 = comparison.pc100 * pmConversions.pm100;
  let compTotal =
    compMass01 +
    compMass03 +
    compMass05 +
    compMass10 +
    compMass25 +
    compMass50 +
    compMass100;
  for (let i = 0; i < pc01Buff.length; i++) {
    let mass01 = pc01Buff[i] * pmConversions.pm01;
    let mass03 = pc03Buff[i] * pmConversions.pm03;
    let mass05 = pc05Buff[i] * pmConversions.pm05;
    let mass10 = pc10Buff[i] * pmConversions.pm10;
    let mass25 = pc25Buff[i] * pmConversions.pm25;
    let mass50 = pc50Buff[i] * pmConversions.pm50;
    let mass100 = pc100Buff[i] * pmConversions.pm100;
    let total = mass01 + mass03 + mass05 + mass10 + mass25 + mass50 + mass100;
    perc01.unshift(mass01 / total / (compMass01 / compTotal));
    perc03.unshift(mass03 / total / (compMass03 / compTotal));
    perc05.unshift(mass05 / total / (compMass05 / compTotal));
    perc10.unshift(mass10 / total / (compMass10 / compTotal));
    perc25.unshift(mass25 / total / (compMass25 / compTotal));
    perc50.unshift(mass50 / total / (compMass50 / compTotal));
    perc100.unshift(mass100 / total / (compMass100 / compTotal));
  }
  return [perc01, perc03, perc05, perc10, perc25, perc50, perc100];
}

function findLatest(valueArray: any[], deviceID: any) {
  //console.log("findLatest: " + deviceID);
  //console.log(valueArray);
  let i = -1;
  valueArray.some((data, index) => {
    i = index;
    return parseInt(data.did) === parseInt(deviceID);
  });
  //console.log("i: " + i)
  return i;
}

function minuteAverage() {
  if (timestampBuff[0] && timestampBuff[0] > Date.now() - 60 * 1000) {
    let i = 0;
    let totals = {
      pc01: 0,
      pc03: 0,
      pc05: 0,
      pc10: 0,
      pc25: 0,
      pc50: 0,
      pc100: 0,
    };
    while (
      i < timestampBuff.length &&
      timestampBuff[i] > Date.now() - 60 * 1000
    ) {
      totals.pc01 += pc01Buff[i];
      totals.pc03 += pc03Buff[i];
      totals.pc05 += pc05Buff[i];
      totals.pc10 += pc10Buff[i];
      totals.pc25 += pc25Buff[i];
      totals.pc50 += pc50Buff[i];
      totals.pc100 += pc100Buff[i];
      i++;
    }
    return {
      pc01: Math.round(totals.pc01 / i),
      pc03: Math.round(totals.pc03 / i),
      pc05: Math.round(totals.pc05 / i),
      pc10: Math.round(totals.pc10 / i),
      pc25: Math.round(totals.pc25 / i),
      pc50: Math.round(totals.pc50 / i),
      pc100: Math.round(totals.pc100 / i),
    };
  }
}

function addToBuffer(value: any, buffer: any[]) {
  // let change = 0;
  // if (buffer.length > 1) {
  //   change = (value-buffer[0].value)/(timestampBuff[0].value-timestampBuff[1].value);
  // }
  //buffer.unshift({value, change});
  buffer.unshift(value);
  buffer = buffer.slice(0, bufferLength);
  return buffer;
}

// Convert raw counts to PM
function pcToPm(
  counts = { pc01: 0, pc03: 0, pc05: 0, pc10: 0, pc25: 0, pc50: 0, pc100: 0 }
) {
  let pm01 = counts.pc01 * pmConversions.pm01 * lToM3;
  let pm03 = counts.pc03 * pmConversions.pm03 * lToM3 + pm01;
  let pm05 = counts.pc05 * pmConversions.pm05 * lToM3 + pm03;
  let pm10 = counts.pc10 * pmConversions.pm10 * lToM3 + pm05;
  let pm25 = counts.pc25 * pmConversions.pm25 * lToM3 + pm10;
  let pm50 = counts.pc50 * pmConversions.pm50 * lToM3 + pm25;
  let pm100 = counts.pc100 * pmConversions.pm100 * lToM3 + pm50;
  return { pm01, pm03, pm05, pm10, pm25, pm50, pm100 };
}

// AQI Functions

function pm25Aqi(con: number) {
  let aqi;
  let category;
  if (con < 12.1) {
    aqi = getAqi(con, 0, 50, 0.0, 12.0);
    category = "good";
  } else if (con < 35.5) {
    aqi = getAqi(con, 51, 100, 12.1, 35.4);
    category = "moderate";
  } else if (con < 55.5) {
    aqi = getAqi(con, 101, 150, 35.5, 55.4);
    category = "unhealthy-sg";
  } else if (con < 150.5) {
    aqi = getAqi(con, 151, 200, 55.5, 150.4);
    category = "unhealthy";
  } else if (con < 250.5) {
    aqi = getAqi(con, 201, 300, 150.5, 250.4);
    category = "very-unhealthy";
  } else if (con < 350.5) {
    aqi = getAqi(con, 301, 400, 250.5, 350.4);
    category = "hazardous";
  } else if (con < 500.5) {
    aqi = getAqi(con, 401, 500, 350.5, 500.4);
    category = "hazardous";
  } else {
    aqi = getAqi(con, 401, 500, 350.5, 500.4);
    //console.log("Outside AQI range (>500.4 ug/m3)");
    category = "hazardous";
  }
  if (aqi < 1) {
    aqi = 1;
  }
  return { value: Math.ceil(aqi), category };
}

function pm100Aqi(con: number) {
  let aqi;
  let category;
  if (con < 55) {
    aqi = getAqi(con, 0, 50, 0.0, 54);
    category = "good";
  } else if (con < 155) {
    aqi = getAqi(con, 51, 100, 55, 154);
    category = "moderate";
  } else if (con < 255) {
    aqi = getAqi(con, 101, 150, 155, 254);
    category = "unhealthy-sg";
  } else if (con < 355) {
    aqi = getAqi(con, 151, 200, 255, 354);
    category = "unhealthy";
  } else if (con < 425) {
    aqi = getAqi(con, 201, 300, 355, 424);
    category = "very-unhealthy";
  } else if (con < 505) {
    aqi = getAqi(con, 301, 400, 425, 504);
    category = "hazardous";
  } else if (con < 605) {
    aqi = getAqi(con, 401, 500, 505, 604);
    category = "hazardous";
  } else {
    aqi = getAqi(con, 401, 500, 505, 604);
    //console.log("Outside AQI range (>604 ug/m3)");
    category = "hazardous";
  }
  if (aqi < 1) {
    aqi = 1;
  }
  return { value: Math.round(aqi), category };
}

function vocAqi(aqi: number) {
  //let aqi;
  let category;
  if (aqi < 1) {
    aqi = 1;
  }

  if (aqi <= 50) {
    category = "good";
  } else if (aqi <= 100) {
    category = "moderate";
  } else if (aqi <= 150) {
    category = "unhealthy-sg";
  } else if (aqi <= 200) {
    category = "unhealthy";
  } else if (aqi <= 300) {
    category = "very-unhealthy";
  } else if (aqi <= 500) {
    category = "hazardous";
  } else {
    category = "hazardous";
  }

  return { value: Math.round(aqi), category };
}

function getAqi(
  con: number,
  iLow: number,
  iHigh: number,
  cLow: number,
  cHigh: number
) {
  let ic = (iHigh - iLow) / (cHigh - cLow);
  return ic * (con - cLow) + iLow;
}

function getAqiLevel(aqi: number) {
  if (aqi < 51) return "good";
  if (aqi < 101) return "moderate";
  if (aqi < 151) return "unhealthy-sg";
  if (aqi < 201) return "unhealthy";
  if (aqi < 301) return "very-unhealthy";

  return "hazardous";
}

function getAqiColor(level: string) {
  // let color = "rgba(150, 150, 150, 1)";
  let color = "#969696";
  switch (level) {
    case "good":
      // color = "rgba(0, 185, 0, 1)";
      color = "#00b900";
      break;
    case "moderate":
      color = "#ffdf00"; // rgba(255, 255, 0, 1) prev color
      break;
    case "unhealthy-sg":
      // color = "rgba(255, 126, 0, 1)";
      color = "#ff7e00";
      break;
    case "unhealthy":
      // color = "rgba(255, 0, 0, 1)";
      color = "#ff0000";
      break;
    case "very-unhealthy":
      color = "#9f124b"; // rgba(153, 0, 76, 1) prev color
      break;
    case "hazardous":
      // color = "rgba(126, 0, 35, 1)";
      color = "#7e0023";
      break;
    default:
      break;
  }
  return color;
}

function getAqiColorHighContrast(level: string) {
  let color = "rgba(150, 150, 150, 1)";
  switch (level) {
    case "good":
      color = "rgba(0, 185, 0, 1)";
      break;
    case "moderate":
      color = "rgba(204, 178, 0, 1)"; // rgba(255, 255, 0, 1) prev color
      break;
    case "unhealthy-sg":
      color = "rgba(255, 126, 0, 1)";
      break;
    case "unhealthy":
      color = "rgba(255, 0, 0, 1)";
      break;
    case "very-unhealthy":
      color = "#9f124b"; // rgba(153, 0, 76, 1) prev color
      break;
    case "hazardous":
      color = "rgba(126, 0, 35, 1)";
      break;
    default:
      break;
  }
  return color;
}

function getVocLevel(aqi: number) {
  if (aqi < 51) return "excellent";
  if (aqi < 101) return "good";
  if (aqi < 151) return "lightly-polluted";
  if (aqi < 201) return "moderately-polluted";
  if (aqi < 251) return "heavily-polluted";
  if (aqi < 351) return "severely-polluted";

  return "extremely-polluted";
}

function getVocColor(level: string) {
  // let color = "rgba(150, 150, 150, 1)";
  let color = "#969696";
  switch (level) {
    case "excellent":
      // color = "rgba(0, 185, 0, 1)";
      color = "#00b900";
      break;
    case "good":
      // color = "rgba(0, 185, 0, 1)";
      color = "#00b900";
      break;
    case "lightly-polluted":
      // color = "rgba(204, 178, 0, 1)"; // rgba(255, 255, 0, 1) prev color
      color = "#ccb200";
      break;
    case "moderately-polluted":
      // color = "rgba(255, 126, 0, 1)";
      color = "#ff7e00";
      break;
    case "heavily-polluted":
      // color = "rgba(255, 0, 0, 1)";
      color = "#ff0000";
      break;
    case "severely-polluted":
      color = "#9f124b"; // rgba(153, 0, 76, 1) prev color
      break;
    case "extremely-polluted":
      // color = "rgba(126, 0, 35, 1)";
      color = "#7e0023";
      break;
    default:
      break;
  }
  return color;
}

function getTimeInterval(date: any) {
  let seconds = Math.floor((Date.now() - date) / 1000);
  let unit = "second";
  let direction = "ago";
  if (seconds < 0) {
    seconds = -seconds;
    direction = "from now";
  }
  let value = seconds;
  if (seconds >= 31536000) {
    value = Math.floor(seconds / 31536000);
    unit = "year";
  } else if (seconds >= 86400) {
    value = Math.floor(seconds / 86400);
    unit = "day";
  } else if (seconds >= 3600) {
    value = Math.floor(seconds / 3600);
    unit = "hour";
  } else if (seconds >= 60) {
    value = Math.floor(seconds / 60);
    unit = "minute";
  }
  if (value !== 1) unit = unit + "s";
  return value + " " + unit + " " + direction;
}
/**
 * Get time since input date.
 *
 * @param date input date to return how long it has been since that time
 * @returns Date
 */
function timeSince(date: any, append?: string) {
  switch (typeof date) {
    case "number":
      break;
    case "string":
      date = +new Date(date);
      break;
    case "object":
      if (date.constructor === Date) date = date.getTime();
      break;
    default:
      date = +new Date();
  }

  var seconds = Math.floor(
    (new Date().valueOf() - new Date(date).valueOf()) / 1000
  );

  var interval = seconds / 31536000;

  if (interval > 1) {
    return [Math.floor(interval).toString(), "years" + (append ? append : "")];
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return [Math.floor(interval).toString(), "months" + (append ? append : "")];
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return [Math.floor(interval).toString(), "days" + (append ? append : "")];
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return [Math.floor(interval).toString(), "hours" + (append ? append : "")];
  }
  interval = seconds / 60;
  if (interval > 1) {
    return [
      Math.floor(interval).toString(),
      "minutes" + (append ? append : ""),
    ];
  }
  return [Math.floor(seconds).toString(), "seconds" + (append ? append : "")];
}

function getTempLevel(temp: number) {
  let fahrenheit = (temp * 9) / 5 + 32;
  // console.log("fahrenheit: ", fahrenheit)
  if (fahrenheit < 32) return "freezing";
  if (fahrenheit < 59) return "cold";
  if (fahrenheit < 68) return "moderate";
  if (fahrenheit < 77) return "slightly-warm";
  if (fahrenheit < 86) return "warm";
  return "hot";
}

function getTempColor(tempLevel: string) {
  // console.log("getTempColor.temp: ", temp)
  // let color = "rgba(150, 150, 150, 1)";
  // console.log("tempLevel: ", tempLevel)

  switch (tempLevel) {
    case "freezing":
      return "#0000e4";
    case "cold":
      return "#00e4e4";
    case "moderate":
      return "#00b900";
    case "slightly-warm":
      return "#ffdf00";
    case "warm":
      return "#ff6600";
    case "hot":
      return "#e40000";
    default:
      return "#ffffff";
  }
}

// function getColorMix(color1: string, color2: string, ratio: number) {
//   var hex = function(x: any) {
//       x = x.toString(16);
//       return (x.length === 1) ? '0' + x : x;
//   };

//   var r = Math.ceil(
//     parseInt(color1.substring(0,2), 16) * ratio
//     + parseInt(color2.substring(0, 2), 16) * (1 - ratio));
//   var g = Math.ceil(
//     parseInt(color1.substring(2,4), 16) * ratio
//     + parseInt(color2.substring(2, 4), 16) * (1 - ratio));
//   var b = Math.ceil(
//     parseInt(color1.substring(4,6), 16) * ratio
//     + parseInt(color2.substring(4, 6), 16) * (1 - ratio));
//   var mix = hex(r) + hex(g) + hex(b);
//   return mix
// }

export {
  processDataBuffer,
  getTimestamps,
  getCounts,
  getPCs,
  getPMs,
  getRatios,
  getRelativeRatios,
  getPercentageByCount,
  getPercentageByCountRelative,
  getPercentageByMass,
  getPercentageByMassRelative,
  findLatest,
  minuteAverage,
  pcToPm,
  pm25Aqi,
  pm100Aqi,
  vocAqi,
  getAqiLevel,
  getAqiColor,
  getAqiColorHighContrast,
  getVocLevel,
  getVocColor,
  getTimeInterval,
  timeSince,
  getTempLevel,
  getTempColor,
  // getColorMix,
};
