import { Provider as AuthProvider } from "./context/AuthContext";
import { Provider as KitProvider } from "./context/KitContext";
import { Provider as SensorProvider } from "./context/SensorContext";
import { Provider as SettingsProvider } from "./context/SettingsContext";
import { Provider as LocationProvider } from "./context/LocationContext";
import { Provider as TestProvider } from "./context/TestContext";

import Routes from "./Routes";

import "./App.scss";

function App(props: any) {
  return (
    <AuthProvider>
      <SensorProvider>
        <KitProvider>
          <SettingsProvider>
            <LocationProvider>
              <TestProvider>
                <Routes />
              </TestProvider>
            </LocationProvider>
          </SettingsProvider>
        </KitProvider>
      </SensorProvider>
    </AuthProvider>
  );
}

export default App;
