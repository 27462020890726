import { useEffect, useState } from "react";
import CSS from "csstype";
import { useTranslation } from "react-i18next";

import moment from "moment";

import { msToTime } from "../../utils/util";

import { ProgressBarProps } from "./types";

import styles from "./progressBar.module.scss";

function ProgressBar({
  testStatus,
  start,
  stop,
  bkgdTime,
  diffTime,
  diffEndTime,
  settTime,
  decayTime,
}: ProgressBarProps) {
  const { t } = useTranslation();

  const [gaugeFillStyles1, setGaugeFillStyles1] = useState({});
  const [gaugeFillStyles3, setGaugeFillStyles3] = useState({});

  const momentComplete = moment(new Date(stop ?? 0));
  const time = `${momentComplete.format("HH:mm MMM D YYYY")}`;

  const [timer, setTimer] = useState("");

  // tick timer
  useEffect(() => {
    const tick = async () => {
      if (start) {
        let msRemaining = 0;

        const testTime = new Date(start);
        const timeElapsed = new Date().getTime() - testTime.getTime();

        if (diffTime && decayTime) {
          if (timeElapsed < bkgdTime * 1000)
            msRemaining = bkgdTime * 1000 - timeElapsed;
          else if (timeElapsed < bkgdTime * 1000 + diffTime * 1000)
            msRemaining = timeElapsed - bkgdTime * 1000;
          else if (
            timeElapsed <
            bkgdTime * 1000 + diffTime * 1000 + settTime * 1000
          )
            msRemaining =
              bkgdTime * 1000 + diffTime * 1000 + settTime * 1000 - timeElapsed;
          else if (
            timeElapsed <
            bkgdTime * 1000 +
              diffTime * 1000 +
              settTime * 1000 +
              decayTime * 1000
          )
            msRemaining =
              timeElapsed - bkgdTime * 1000 - diffTime * 1000 - settTime * 1000;
          else {
            msRemaining = -1;
          }
        }
        if (msRemaining > 0) {
          setTimer(msToTime(msRemaining));
        } else if (msRemaining === 0) {
          setTimer("00:00");
        } else setTimer("");
      }
    };
    let intervalFunction = setInterval(tick, 1000);
    tick();
    return () => {
      clearInterval(intervalFunction);
    };
  }, [testStatus]);

  // gauge styles
  // clean up and move somewhere else
  useEffect(() => {
    const testTime = new Date(start);
    const timeElapsed = new Date().getTime() - testTime.getTime();

    if (
      testStatus === "settling" ||
      testStatus === "measuring" ||
      testStatus === "success"
    ) {
      let msRemaining;
      if (diffTime) {
        msRemaining =
          bkgdTime * 1000 + diffTime * 1000 + settTime * 1000 - timeElapsed;
      } else if (diffEndTime) {
        msRemaining =
          settTime * 1000 -
          (new Date().getTime() - new Date(diffEndTime).getTime());
      }
      msRemaining = !!msRemaining && msRemaining > 0 ? msRemaining : 0;
      const gaugeWidth =
        ((settTime * 1000 - msRemaining) / (settTime * 1000)) * 74;
      const gaugeFill3: CSS.Properties = {
        width: `${
          gaugeWidth >= 5 ? (gaugeWidth <= 74 ? gaugeWidth : 74) : 5
        }px`,
      };
      setGaugeFillStyles3(gaugeFill3);
    }
    if (!!start) {
      let msRemaining = bkgdTime * 1000 - timeElapsed;
      msRemaining = !!msRemaining && msRemaining > 0 ? msRemaining : 0;
      const gaugeWidth =
        ((bkgdTime * 1000 - msRemaining) / (bkgdTime * 1000)) * 74;
      const gaugeFill1: CSS.Properties = {
        width: `${
          gaugeWidth >= 5 ? (gaugeWidth <= 74 ? gaugeWidth : 74) : 5
        }px`,
      };
      setGaugeFillStyles1(gaugeFill1);
      return () => {
        setGaugeFillStyles1({});
        setGaugeFillStyles3({});
      };
    }
  }, [
    timer,
    testStatus,
    start,
    stop,
    bkgdTime,
    diffTime,
    diffEndTime,
    settTime,
    decayTime,
  ]);

  return (
    <div className={styles.progressContainer}>
      <div className={styles.status}>
        <span>
          <span>
            {testStatus !== "error" &&
              `${t("progress")}: ${t(`testStatus.${testStatus}`)}`}
            {testStatus === "error" && `${t("testStatus.stopped")}:`}
          </span>
        </span>
        <span>
          <p>
            {(testStatus === "background" || testStatus === "settling") && (
              <>
                <b>{timer}</b> {!!timer.length && t("remaining")}
              </>
            )}
            {(testStatus === "diffusion" || testStatus === "measuring") && (
              <>
                <b>{timer}</b> {!!timer.length && t("elapsed")}
              </>
            )}
            {(testStatus === "success" || testStatus === "stopped") && (
              <>{time}</>
            )}
            {testStatus === "error" && <b>{time}</b>}
          </p>
        </span>
      </div>
      <div className={styles.progressBars}>
        <div className={`${styles.progressBar} ${styles.progressBar1}`}>
          <div
            style={gaugeFillStyles1}
            className={`${styles.progressBarFill} ${styles.progressBarFill1}`}
          ></div>
          <div className={styles.progressBarBackground}></div>
        </div>
        <div className={`${styles.progressBar} ${styles.progressBar2}`}>
          <div
            className={`${styles.progressBarFill} ${
              testStatus === "diffusion"
                ? styles.progressBarFillOn2
                : testStatus !== "background" &&
                  testStatus !== "loading" &&
                  styles.progressBarFill2
            }`}
          ></div>
          <div className={styles.progressBarBackground}></div>
        </div>
        <div className={`${styles.progressBar} ${styles.progressBar3}`}>
          <div
            style={gaugeFillStyles3}
            className={`${styles.progressBarFill} ${styles.progressBarFill3}`}
          ></div>
          <div className={styles.progressBarBackground}></div>
        </div>
        <div className={`${styles.progressBar} ${styles.progressBar2}`}>
          <div
            className={`${styles.progressBarFill} ${
              testStatus === "measuring"
                ? styles.progressBarFillOn2
                : testStatus === "success" && styles.progressBarFill2
            }`}
          ></div>
          <div className={styles.progressBarBackground}></div>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
