import { useContext, useEffect, useState } from "react";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as KitContext } from "../../context/KitContext";
import { Context as LocationContext } from "../../context/LocationContext";
import { Context as SensorContext } from "../../context/SensorContext";
import { Context as SettingsContext } from "../../context/SettingsContext";
import { Context as TestContext } from "../../context/TestContext";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IoNotifications } from "react-icons/io5";
import { BsCircleFill } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { FiChevronDown } from "react-icons/fi";
import { HiViewGrid } from "react-icons/hi";
import { MdSpaceDashboard } from "react-icons/md";
import { IoInformationCircle } from "react-icons/io5";
import { IoSettings } from "react-icons/io5";

import { paths } from "../../utils/const";
import logoImg from "./../../assets/images/poppy-logo.png";
import iconImg from "./../../assets/images/poppy-icon.png";

import styles from "./nav.module.scss";
const { version } = require("../../../package.json");

function Nav() {
  const { t } = useTranslation();
  const { state: authState, signout } = useContext(AuthContext);
  const { resetState: resetKitState } = useContext(KitContext);
  const { resetState: resetSensorState } = useContext(SensorContext);
  const {
    state: { tid, testStatus },
    toggleStart,
    resetState: resetTestState,
  } = useContext(TestContext);
  const { resetState: resetLocationState } = useContext(LocationContext);
  const { state: settings, resetState: resetSettingsState } =
    useContext(SettingsContext);
  const location = useLocation();
  const [screenTitle, setScreenTitle] = useState("");
  const [hasNotifications, setHasNotifications] = useState(true);
  // const [notificationsLength, setNotificationsLength] = useState(5)

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  function handleSignout() {
    resetLocationState();
    resetTestState();
    resetKitState();
    resetSensorState();
    resetSettingsState();
    signout();
  }

  // useEffect run on Route Change
  useEffect(() => {
    function getScreenTitle(pathname: any): string {
      if (pathname === "/dashboard") {
        return t("dashboard");
      } else if (pathname === "/settings") {
        return t("settings.settings");
      } else if (pathname === "/account") {
        return t("account-info");
      } else if (
        pathname.startsWith("/test") ||
        pathname.startsWith("/each") ||
        pathname.startsWith("/demo")
      ) {
        return t("rapid-test");
      } else if (pathname.startsWith("/home")) {
        return t("home");
      } else if (pathname.startsWith("/location")) {
        return t("location");
      } else {
        return t("device-details");
      }
    }
    setIsMobileMenuOpen(false);
    setIsProfileMenuOpen(false);
    setScreenTitle(getScreenTitle(location.pathname));
    setHasNotifications(true);
    // setNotificationsLength(5)
  }, [location.pathname, settings, t]);

  return (
    <>
      <div className={styles.mainBar}>
        <NavLink
          className={`${styles.mainBarButton}`}
          onClick={() => {
            toggleStart(false);
            resetLocationState();
            resetTestState();
          }}
          to={paths.home}
        >
          <img className={styles.logoImage} src={iconImg} alt="" />
        </NavLink>
        <b className={styles.navTitle}>{screenTitle}</b>
        <div className={styles.navIcons}>
          {authState.signin ? (
            <>
              <div className={styles.iconGroup}>
                <IoNotifications className={styles.iconNotificationBell} />
                <BsCircleFill
                  className={`${styles.iconNotificationDot} ${
                    hasNotifications ? styles.hasNotifications : ""
                  }`}
                />
                {/* <p className={`${styles.notificationsLength} ${(hasNotifications ? styles.hasNotifications : '')}`}>{notificationsLength}</p> */}
              </div>
              <div
                className={styles.iconGroup}
                onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
              >
                <CgProfile className={styles.iconProfile} />
                <FiChevronDown className={styles.iconChevron} />
              </div>
            </>
          ) : (
            <>
              <NavLink className={`${styles.mainBarButton}`} to={paths.login}>
                {t("login")}
              </NavLink>
              <NavLink className={`${styles.mainBarButton}`} to={paths.signup}>
                {t("create-account")}
              </NavLink>
            </>
          )}
        </div>
        <HiViewGrid
          className={`${styles.iconMobileMenu} ${
            isMobileMenuOpen ? styles.iconMobileMenuOpen : ""
          }`}
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        />
      </div>
      <div className={styles.navBar}>
        <img
          className={styles.logoImage}
          src={logoImg}
          alt="Poppy Health Logo"
        />
        <nav>
          {authState.signin ? (
            <>
              <NavLink
                exact
                className={styles.navLink}
                activeClassName={styles.active}
                onClick={() => {
                  toggleStart(false);
                  resetLocationState();
                  resetTestState();
                }}
                to={`/home`}
              >
                <MdSpaceDashboard className={styles.navLinkIcon} />
                {t("home")}
              </NavLink>
              {testStatus === "running" ? (
                <NavLink
                  className={styles.navLink}
                  activeClassName={styles.active}
                  to={`/${
                    settings.testType === "241" ? "test" : settings.testType
                  }/${tid}`}
                  isActive={(match, location) =>
                    !!(
                      location.pathname.startsWith("/test") ||
                      location.pathname.startsWith("/each") ||
                      location.pathname.startsWith("/demo")
                    )
                  }
                >
                  <IoInformationCircle className={styles.navLinkIcon} />
                  {t("rapid-test")}
                </NavLink>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          <NavLink
            className={styles.navLink}
            activeClassName={styles.active}
            to={paths.settings}
          >
            <IoSettings className={styles.navLinkIcon} />
            {t("settings.settings")}
          </NavLink>
        </nav>
        <p className={styles.appVersionNumberLabel}>{version}</p>
      </div>
      <nav
        className={`${styles.mobileNavBar} ${
          isMobileMenuOpen ? styles.mobileNavBarOpen : styles.mobileNavBarClosed
        }`}
      >
        {/* {authState.signin ? (
          <NavLink className={styles.navProfileGroup} to={paths.account}>
            <CgProfile className={styles.iconProfile} />
            <FaUserEdit className={styles.iconEditProfile} />
          </NavLink>
        ) : (
          <></>
        )} */}
        <div className={styles.navLinkGroup}>
          {authState.signin ? (
            <>
              <NavLink
                className={styles.navLink}
                activeClassName={styles.active}
                onClick={() => {
                  toggleStart(false);
                  resetLocationState();
                  resetTestState();
                }}
                to={`/home`}
              >
                <MdSpaceDashboard className={styles.navLinkIcon} />
                {t("home")}
              </NavLink>
              {tid > 0 ? (
                <NavLink
                  className={styles.navLink}
                  activeClassName={styles.active}
                  exact={false}
                  to={`/${
                    settings.testType === "241" ? "test" : settings.testType
                  }/${tid}`}
                  isActive={(match, location) =>
                    !!(
                      location.pathname.startsWith("/test") ||
                      location.pathname.startsWith("/each") ||
                      location.pathname.startsWith("/demo")
                    )
                  }
                >
                  <IoInformationCircle className={styles.navLinkIcon} />
                  {t("rapid-test")}
                </NavLink>
              ) : (
                <></>
              )}
              <NavLink
                className={styles.navLink}
                activeClassName={styles.active}
                to={paths.settings}
              >
                <IoSettings className={styles.navLinkIcon} />
                {t("settings.settings")}
              </NavLink>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className={styles.mobileProfileLinks}>
          <a className={styles.profileMenuLink} href={paths.support}>
            {t("support")}
          </a>
          {!authState.signin ? (
            <>
              <NavLink className={`${styles.profileMenuLink}`} to={paths.login}>
                {t("login")}
              </NavLink>
              <NavLink
                className={`${styles.profileMenuLink}`}
                to={paths.signup}
              >
                {t("create-account")}
              </NavLink>
            </>
          ) : (
            <button
              className={`${styles.profileMenuLink} ${styles.signoutButton}`}
              onClick={handleSignout}
            >
              {t("logout")}
            </button>
          )}
        </div>
        <p className={styles.appVersionNumberLabel}>{version}</p>
      </nav>
      {authState.signin ? (
        <div
          className={`${styles.profileMenu} ${
            isProfileMenuOpen
              ? styles.profileMenuOpen
              : styles.profileMenuClosed
          }`}
        >
          {/* <div className={styles.profileMenuLinkGroup}>
            <NavLink className={styles.profileMenuLink} to={paths.account}>
              {t("account")}
            </NavLink>
          </div>
          <div className={styles.profileMenuDivideLine}></div> */}
          <div className={styles.profileMenuLinkGroup}>
            <a className={styles.profileMenuLink} href={paths.support}>
              {t("support")}
            </a>
          </div>
          <div className={styles.profileMenuDivideLine}></div>
          <div className={styles.profileMenuLinkGroup}>
            <button
              className={`${styles.profileMenuLink} ${styles.signoutButton}`}
              onClick={handleSignout}
            >
              {t("logout")}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Nav;
