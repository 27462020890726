export function findTarget(category: string) {
  switch (category.toLowerCase()) {
    case "office":
      return 30;
    case "educational":
      return 40;
    case "food and beverage facilities":
      return 60;
    case "residential":
      return 30;
    case "retail":
      return 40;
    case "gym":
      return 80;
    case "public assembly spaces":
      return 50;
    case "place of religious worship":
      return 50;
    case "healthcare":
      return 70;
    case "warehouse":
      return 20;
    case "museum":
      return 60;
    case "hotel":
      return 50;
    case "correctional-facilities-cell":
      return 30;
    case "correctional-facilities-dayroom":
      return 40;
    case "commercial-food-and-beverage":
      return 60;
    case "commercial-gym":
      return 80;
    case "commercial-office":
      return 30;
    case "commercial-retail":
      return 40;
    case "commercial-transportation":
      return 60;
    case "education-classroom":
      return 40;
    case "education-lecture-hall":
      return 50;
    case "education-gym":
      return 80;
    case "education-lobby-open-space":
      return 50;
    case "industrial-manufacturing":
      return 50;
    case "industrial-sorting-and-packing":
      return 20;
    case "industrial-warehouse":
      return 20;
    case "health-exam-room":
      return 40;
    case "health-group-treatment-area":
      return 70;
    case "health-patient-room":
      return 70;
    case "health-resident-room":
      return 50;
    case "health-waiting-room":
      return 90;
    case "public-auditorium":
      return 50;
    case "public-place-of-religious-worship":
      return 50;
    case "public-museum":
      return 60;
    case "public-convention":
      return 60;
    case "public-spectator-area":
      return 50;
    case "public-lobbies":
      return 50;
    case "residential-common-space":
      return 50;
    case "residential-dwelling-unit":
      return 30;
  }
  return 0;
}
