import { useTranslation } from "react-i18next";

import { timeSince } from "../../../calculations";

import Input from "../../../components/Input";
import Modal from "../../../components/BaseModal";
import SensorStatusList from "../../../components/SensorStatusList";

import LatestMeasurement from "../../../components/LatestMeasurement";
import ProgressBar from "../../../components/ProgressBar";

import { useConnect } from "./connect";

import styles from "./demo.module.scss";
import { useLocation } from "react-router-dom";
import { TestEACH } from "../../../utils/types";
import moment from "moment";
import TestLocationInfo from "../../../components/TestLocationInfo";

const TestDemo = () => {
  const { t } = useTranslation();
  const loc = useLocation();

  const {
    allDevices,
    kitDevices,
    location,
    site,
    zone,
    rapidTest,
    chartData,
    hideEach,
    buttonText,
    returnHome,
    stopRapidTest,
    toggleStopModal,
    showStopModal,
    errorModalActions,
    notes,
    handleNotesChange,
    submitNotes,
    notesDisabledSubmit,
    rapidTestTimeRemaining,
    testStatus,
    showDiffuseModal,
    closeDiffuserModal,
  } = useConnect();

  const momentComplete = moment(new Date(rapidTest.start ?? 0));

  const time = `${momentComplete.format("HH:mm MMM D YYYY")}`;

  return (
    <div className={styles.root}>
      <div className={styles.demoHeader}>{t("demo.sample")}</div>
      <div className={styles.demoHeaderBuffer} />
      <TestLocationInfo location={location} site={site} zone={zone} />
      {/* <ProgressBar test={rapidTest as TestEACH} /> */}
      <ProgressBar
        testStatus={testStatus}
        start={rapidTest.start}
        stop={rapidTest.stop}
        bkgdTime={(rapidTest as TestEACH).bkgd_time}
        diffTime={(rapidTest as TestEACH).diff_time}
        settTime={(rapidTest as TestEACH).sett_time}
        decayTime={(rapidTest as TestEACH).decay_time}
      />

      <div className={styles.buttonContainer}>
        <button
          className={styles.rapidTestActionButton}
          onClick={returnHome}
          disabled={testStatus !== "success" && testStatus !== "error"}
        >
          {t(buttonText)}
        </button>
      </div>
      <LatestMeasurement
        rapidTest={rapidTest as TestEACH}
        chartData={chartData}
        locationCategory={zone?.type ?? ""}
        peakOccupancy={zone?.peak ?? 0}
        zoneVolume={
          zone?.volume && zone.volume > 0
            ? zone.volume
            : (zone?.length ?? 0) * (zone?.width ?? 0) * (zone?.height ?? 0)
        }
        hideEach={hideEach}
        testType={"demo"}
      />
      {rapidTest?.status === "success" && (
        <div className={styles.demoModeDescription}>
          <span className={styles.textContainer}>
            <p className={styles.header}>{t("demo.demoMode")}</p>
            <p className={styles.description}>{t("demo.description")}</p>
          </span>
        </div>
      )}
      {/* <SensorMeasurements
        rapidTest={rapidTest}
        sensors={sensorState.allDevices}
        peakOccupancy={zone?.peak ?? 0}
      /> */}
      {rapidTest.status !== "success" && rapidTest.status !== "error" && (
        <SensorStatusList
          kitDevices={kitDevices}
          allSensors={allDevices}
          testType="demo"
        />
      )}
      <div className={styles.notesContainer}>
        <h2 className={styles.header}>{t("notes")}</h2>
        <div className={styles.inputGroup}>
          <Input
            className={styles.notesInput}
            placeholder={""}
            type={"textarea"}
            name={"notes"}
            value={notes}
            maxLength={255}
            onChange={handleNotesChange}
          />
        </div>
        <button
          className={styles.submitNotes}
          onClick={submitNotes}
          disabled={notesDisabledSubmit}
        >
          {t("save")}
        </button>
      </div>
      {!rapidTest?.stop ? (
        <div className={styles.buttonContainer}>
          <button className={styles.stopRapidTest} onClick={toggleStopModal}>
            {t("stop")}
          </button>
        </div>
      ) : (
        <></>
      )}
      {/* Export all of these modals into sub folders using BaseModal to extend, cleaning up this file */}
      <Modal
        title={
          showDiffuseModal === "start"
            ? t("modal.nebulize.start.title")
            : t("modal.nebulize.stop.title")
        }
        children={
          showDiffuseModal === "start" ? (
            <>
              <p className={styles.typographyMessage}>
                {t("modal.nebulize.start.message", {
                  time: rapidTestTimeRemaining,
                })}
              </p>
              <p className={styles.typographyMessageHint}>
                {t("modal.nebulize.start.description")}
              </p>
            </>
          ) : (
            <p className={styles.typographyMessageHint}>
              {t("modal.nebulize.stop.description")}
            </p>
          )
        }
        actions={{
          onClick: closeDiffuserModal,
          type: "danger",
          text: t("modal.button.close"),
        }}
        isOpen={!!showDiffuseModal && showDiffuseModal !== "hide"}
      />
      <Modal
        title={t("stop-test-title")}
        children={
          <>
            <p className={styles.typographyMessage}>{t("stop-test-message")}</p>
            <p className={styles.typographyMessage}>
              {t("stop-test-description")}
            </p>
          </>
        }
        actions={[
          {
            onClick: toggleStopModal,
            type: "default",
            text: t("modal.button.cancelStop"),
          },
          {
            onClick: stopRapidTest,
            type: "danger",
            text: t("modal.button.confirmStop"),
          },
        ]}
        isOpen={showStopModal}
      />
      <Modal
        title={
          rapidTest.errorcode === 112
            ? t("modal.error.bgTimeout")
            : rapidTest.errorcode === 113
            ? t("modal.error.nebulizationTimeoutTitle")
            : "Error"
        }
        children={
          rapidTest.errorcode === 112 ? (
            <>
              <p className={styles.typographyMessage}>
                {t("modal.error.bgTooLong")}
              </p>
              <p className={styles.typographyMessage}>
                {t("modal.error.restartTest")}
              </p>
            </>
          ) : rapidTest.errorcode === 113 ? (
            <>
              <p className={styles.typographyMessage}>
                {t("modal.error.nebulizationTimeout")}
              </p>
              <p className={styles.typographyMessage}>
                {t("modal.error.restartTest")}
              </p>
              <p className={styles.typographyMessageHint}>
                {t("modal.error.nebulizationHint")}
              </p>
            </>
          ) : (
            <></>
          )
        }
        actions={errorModalActions}
        isOpen={rapidTest.status === "error"}
      />
    </div>
  );
};

export default TestDemo;
