import moment from "moment";

import { useTranslation } from "react-i18next";
import { useConnect } from "./connect";
import { FaCheckCircle } from "react-icons/fa";
import { IoTriangle } from "react-icons/io5";
import { IoTriangleOutline } from "react-icons/io5";

import { getAqiLevel, timeSince } from "../../../calculations";

import DeviceLineChart from "../../../components/DeviceLineChart";
import Input from "../../../components/Input";
import Modal from "../../../components/BaseModal";
import SensorStatusList from "../../../components/SensorStatusList";

import styles from "./241.module.scss";
import TestLocationInfo from "../../../components/TestLocationInfo";
import SensorMeasurements from "../../../components/SensorMeasurements";

const RapidTest = () => {
  const { t } = useTranslation();

  const {
    chartData,
    allDevices,
    kitDevices,
    eachAvg,
    gavfrActual,
    gavfrTarget,
    resultAvg,
    cfmDiff,
    gaugeFillStyles1,
    gaugeFillStyles3,
    location,
    site,
    zone,
    rapidTestTimeRemaining,
    rapidTest,
    sensorMeasurements,
    sensorState,
    disabledStart,
    startRapidTest,
    startButtonText,
    stopRapidTest,
    toggleStopModal,
    showStopModal,
    showDiffuseModal,
    errorModalActions,
    closeDiffuserModal,
    testResult,
    passesForOccupants,
    hideEach,
    notes,
    handleNotesChange,
    submitNotes,
    notesDisabledSubmit,
  } = useConnect();

  const momentComplete = moment(new Date(rapidTest.start ?? 0));

  const time = `${momentComplete.format("HH:mm MMM D YYYY")}`;
  return (
    <div className={styles.root}>
      <TestLocationInfo location={location} site={site} zone={zone} />
      {rapidTest.start && rapidTest.start !== "" && (
        <div className={styles.progressContainer}>
          <div className={styles.status}>
            <span>
              <span>
                {rapidTest.status !== "error" &&
                  `${t("progress")}: ${t(`testStatus.${rapidTest.status}`)}`}
                {rapidTest.status === "error" &&
                  rapidTest?.errorcode === 1 &&
                  `${t("testStatus.stopped")}:`}
              </span>
            </span>
            <span>
              <p>
                {(rapidTest.status === "background" ||
                  rapidTest.status === "settling") && (
                  <>
                    <b>{rapidTestTimeRemaining}</b>{" "}
                    {rapidTestTimeRemaining && t("remaining")}
                  </>
                )}
                {rapidTest.status === "diffusion" && (
                  <>
                    <b>{rapidTestTimeRemaining}</b>{" "}
                    {rapidTestTimeRemaining && t("elapsed")}
                  </>
                )}
                {(rapidTest.status === "measuring" ||
                  rapidTest.status === "processing") && (
                  <>
                    <b>{rapidTestTimeRemaining}</b>{" "}
                    {rapidTestTimeRemaining && t("elapsed")}
                  </>
                )}
                {rapidTest.status === "success" && <>{time}</>}
                {rapidTest.status === "error" && rapidTest?.errorcode === 1 && (
                  <b>{`${time}`}</b>
                )}
              </p>
            </span>
          </div>
          <div className={styles.progressBars}>
            <div className={`${styles.progressBar} ${styles.progressBar1}`}>
              <div
                style={gaugeFillStyles1}
                className={`${styles.progressBarFill} ${styles.progressBarFill1}`}
              ></div>
              <div className={styles.progressBarBackground}></div>
            </div>
            <div className={`${styles.progressBar} ${styles.progressBar2}`}>
              <div
                className={`${styles.progressBarFill} ${
                  rapidTest.status === "diffusion"
                    ? styles.progressBarFillOn2
                    : rapidTest.status !== "background"
                    ? styles.progressBarFill2
                    : styles.progressBarFillOff2
                }`}
              ></div>
              {/* <div className={styles.progressBarBackground}></div> */}
            </div>
            <div className={`${styles.progressBar} ${styles.progressBar3}`}>
              <div
                style={gaugeFillStyles3}
                className={`${styles.progressBarFill} ${styles.progressBarFill3}`}
              ></div>
              <div className={styles.progressBarBackground}></div>
            </div>
            <div className={`${styles.progressBar} ${styles.progressBar2}`}>
              <div
                className={`${styles.progressBarFill} ${
                  rapidTest.status === "measuring"
                    ? styles.progressBarFillOn2
                    : rapidTest.status === "success" && styles.progressBarFill2
                }`}
              ></div>
              <div className={styles.progressBarBackground}></div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.buttonContainer}>
        <button
          className={styles.startRapidTest}
          onClick={startRapidTest}
          disabled={disabledStart}
        >
          {t(startButtonText)}
        </button>
      </div>
      {rapidTest.status !== "" && (
        <div className={styles.interimResultContainer}>
          <h2 className={styles.header}>{t("header.latestMeasurement")}</h2>
          {!hideEach && (
            <div className={styles.eachContainer}>
              <span className={styles.label}>{t("label.each")}</span>
              <p className={styles.value}>
                {eachAvg !== -1 ? eachAvg?.toFixed(1) : "- "}
              </p>
              <p className={styles.subLabel}>
                {`${t("eACH")}`}
                {/* <sub>{"avg"}</sub> */}
                {` (hr`}
                <sup>-1</sup>
                {")"}
              </p>
            </div>
          )}
          <div className={styles.measurementsContainer}>
            <span className={styles.mainValueLabel}>
              {t("label.average-equivalent", {
                people: rapidTest.occupancy ?? zone?.peak ?? 0,
              })}
            </span>
            <span className={styles.measurement}>
              <p className={styles.value}>
                <b>
                  {!!gavfrActual && gavfrActual !== -1
                    ? `${gavfrActual}  `
                    : "- "}
                </b>
              </p>
              <p className={styles.label}>
                <b>{t("label.actual")}</b>
              </p>
              <p className={styles.label}>{t("label.cfm-per-person")}</p>
            </span>
            <span className={styles.measurement}>
              <p className={styles.value}>
                <b>{gavfrTarget !== -1 ? `${gavfrTarget ?? "- "} ` : "- "}</b>
              </p>
              <p className={styles.label}>
                <b>{t("label.target")}</b>
              </p>
              <p className={styles.label}>{t("label.cfm-per-person")}</p>
            </span>
          </div>
          {(testResult === "fail" || testResult === "success") &&
          !!gavfrActual &&
          !!resultAvg ? (
            <>
              <div
                className={`${styles.testResult} ${
                  testResult === "success"
                    ? styles.testPassed
                    : styles.testFailed
                }`}
              >
                <div className={styles.testResultBackground}>
                  {testResult === "success" ? (
                    <FaCheckCircle className={styles.checkCircle} />
                  ) : (
                    <span className={styles.failIconContainer}>
                      <IoTriangleOutline className={styles.triangleOutline} />
                      <IoTriangle className={styles.triangle} />
                    </span>
                  )}
                  <span className={styles.textContainer}>
                    <p className={styles.result}>
                      <b>{testResult === "success" ? "PASS" : "FAIL"}</b>
                    </p>
                    <p className={styles.value}>
                      <b>
                        at {rapidTest.occupancy ?? zone?.peak ?? "-"} occupants
                      </b>
                    </p>
                    <p className={styles.standard}>
                      <b>Standard 241-2023</b>
                    </p>
                  </span>
                </div>
              </div>
              <div className={styles.differentialContainer}>
                <span className={styles.header}>
                  {t("label.average-volumetric-differential")}
                </span>
                <span>
                  <p className={styles.value}>
                    <b>{!!cfmDiff ? cfmDiff : "0"}</b>
                  </p>
                  <p className={styles.label}>
                    <b>
                      {testResult === "fail"
                        ? t("label.additional-cfm-required", {
                            occupants: rapidTest.occupancy ?? zone?.peak,
                          })
                        : t("label.surplus-cfm")}
                    </b>
                  </p>
                  <p className={styles.label}>
                    <b>{t("label.surplus-cfm-diff")}</b>
                  </p>
                </span>
                {testResult === "fail" && !!passesForOccupants && (
                  <span className={styles.passesForOccupants}>
                    <b>
                      {t("label.pass-for-occupants", {
                        occupants: passesForOccupants,
                      })}
                    </b>
                  </span>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
          {testResult !== "fail" && testResult !== "success" && (
            <DeviceLineChart
              type="PM1.0"
              key="PM1.0 Chart"
              chartData={chartData}
              range={1000}
              colorFunction={(number: number) => {
                return "#64E8DE";
              }}
              levelFunction={getAqiLevel}
              hideTooltip
            />
          )}
        </div>
      )}
      {!!sensorMeasurements.length && (
        <div className={styles.sensorMeasurementsContainer}>
          <h2 className={styles.header}>
            {`${t("header.wavfr")} ${t("label.cfm-per-person")}`}
          </h2>
          <div className={styles.columnsHeader}>
            <span className={styles.columnLabel}>{t("sensor")}</span>
            <span className={styles.columnLabel}>
              {t("label.eca")}
              <sub>A</sub>
            </span>
          </div>
          <div className={styles.sensors}>
            {sensorMeasurements?.map((sensor, i) => (
              <span className={styles.measurements} key={i}>
                <p>{sensor.name}</p>
                <p className={sensor.isLowest ? styles.lowest : ""}>
                  {sensor.value}
                </p>
              </span>
            )) ?? null}
          </div>
        </div>
      )}
      {rapidTest.status !== "success" && rapidTest.status !== "error" && (
        <SensorStatusList
          kitDevices={kitDevices}
          // kitPlusSensors={kitPlusDevices}
          allSensors={allDevices}
          testType="241"
        />
      )}
      <div className={styles.notesContainer}>
        <h2 className={styles.header}>{t("notes")}</h2>
        <div className={styles.inputGroup}>
          <Input
            className={styles.notesInput}
            placeholder={""}
            type={"textarea"}
            name={"notes"}
            value={notes}
            maxLength={255}
            onChange={handleNotesChange}
          />
        </div>
        <button
          className={styles.submitNotes}
          onClick={submitNotes}
          disabled={notesDisabledSubmit}
        >
          {t("save")}
        </button>
      </div>
      {!rapidTest?.stop ? (
        <div className={styles.buttonContainer}>
          <button className={styles.stopRapidTest} onClick={toggleStopModal}>
            {t("stop")}
          </button>
        </div>
      ) : (
        <></>
      )}
      {/* Export all of these modals into sub folders using BaseModal to extend, cleaning up this file */}
      <Modal
        title={
          showDiffuseModal === "start"
            ? t("modal.nebulize.start.title")
            : t("modal.nebulize.stop.title")
        }
        children={
          showDiffuseModal === "start" ? (
            <>
              <p className={styles.typographyMessage}>
                {t("modal.nebulize.start.message", {
                  time: rapidTestTimeRemaining,
                })}
              </p>
              <p className={styles.typographyMessageHint}>
                {t("modal.nebulize.start.description")}
              </p>
            </>
          ) : (
            <p className={styles.typographyMessageHint}>
              {t("modal.nebulize.stop.description")}
            </p>
          )
        }
        actions={{
          onClick: closeDiffuserModal,
          type: "danger",
          text: t("modal.button.close"),
        }}
        isOpen={!!showDiffuseModal && showDiffuseModal !== "hide"}
      />
      <Modal
        title={t("stop-test-title")}
        children={
          <>
            <p className={styles.typographyMessage}>{t("stop-test-message")}</p>
            <p className={styles.typographyMessage}>
              {t("stop-test-description")}
            </p>
          </>
        }
        actions={[
          {
            onClick: toggleStopModal,
            type: "default",
            text: t("modal.button.cancelStop"),
          },
          {
            onClick: stopRapidTest,
            type: "danger",
            text: t("modal.button.confirmStop"),
          },
        ]}
        isOpen={showStopModal}
      />
      <Modal
        title={
          rapidTest.errorcode === 112
            ? t("modal.error.bgTimeout")
            : rapidTest.errorcode === 113
            ? t("modal.error.nebulizationTimeoutTitle")
            : "Error"
        }
        children={
          rapidTest.errorcode === 112 ? (
            <>
              <p className={styles.typographyMessage}>
                {t("modal.error.bgTooLong")}
              </p>
              <p className={styles.typographyMessage}>
                {t("modal.error.restartTest")}
              </p>
            </>
          ) : rapidTest.errorcode === 113 ? (
            <>
              <p className={styles.typographyMessage}>
                {t("modal.error.nebulizationTimeout")}
              </p>
              <p className={styles.typographyMessage}>
                {t("modal.error.restartTest")}
              </p>
              <p className={styles.typographyMessageHint}>
                {t("modal.error.nebulizationHint")}
              </p>
            </>
          ) : (
            <></>
          )
        }
        actions={errorModalActions}
        isOpen={rapidTest.status === "error"}
      />
    </div>
  );
};

export default RapidTest;
